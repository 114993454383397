var siteGlobals = {
  csrfToken : false,    // a global STRING to store the session csrf token value
  consoleLog: false,    // a local global BOOLEAN to store whether or not consoleLog is
                        // available
  $window           : null,     // a local global to store jQuery access to the window object
  $document         : null,     // a local global to store jQuery access to the document object
  $html             : null,     // a local global to store jQuery access to the root html element
  $head             : null,     // a local global to store jQuery access to the body element
  $body             : null,     // a local global to store jQuery access to the body element
  $site_page_content: null,     // a local global to store jQuery access to the '#sh-page-content'
  // element
  $js_construction_zone: null,     // a local global to store jQuery access to the
                                   // '#js-construction-zone' element
  $toaster_notifications_container: null,     // a local global to store jQuery access to the
                                              // '#toaster-notifications-container' element
  values: null,     // a local global array of arrays to store safe values loaded from the
                    // server
  endOfPageScroll: null,     // a local global INTEGER to store the scrollTop that ends at the
                             // bottom of the page
  url     : null,     // a local global to store the current browser url
  base_url: null,     // a local global that contains the protocol and hostname (ie.
                      // 'http://sub.example.com/' or 'https://sub.example.com/')
  $css_modal_checkboxes: null,     // a local global to store jQuery access to '.css-modal-checkbox'
                                   // elements

  millisecondsToSeconds: function(time) {
    return parseFloat(time) / 1000.0;
  },
  secondsToMinutes     : function(time) {
    return parseFloat(time) / 60.0;
  },
  minutesToHours       : function(time) {
    return parseFloat(time) / 60.0;
  },
  secondsToMilliseconds: function(time) {
    return parseFloat(time) * 1000.0;
  },
  minutesToSeconds     : function(time) {
    return parseFloat(time) * 60.0;
  },
  hoursToMinutes       : function(time) {
    return parseFloat(time) * 60.0;
  },
  syncInputs           : function($inputElements) {
    $inputElements.on("keyup search", function() {
      if ($(this).data("skip-update")) {
        return true;
      }
      $inputElements.not($(this)).val($(this).val()).each(function() {
        $(this).data("skip-update", true).keyup().data("skip-update", false);
      });
      return true;
    });
  },

  // bootstrapOnlyHoverActivateHandler: function(e)
  // {
  //  if(e.stopPropagation){ e.stopPropagation()};
  // },

  scrollToKb: function(kbId) {
    if (typeof kbId !== "undefined" && $("#kb-article-" + kbId).length) {
      $("#kb-side-bar").animate({
        scrollTop: $("#kb-article-" + kbId).offset().top - 100
      }, 500);
    }
  },


  pulseElement: function(element, color) {
    if (typeof color !== 'string') {
      color = 'orange';
    }
    var classToAdd = "pulse-orange";
    switch (color) {
      case 'blue':
        classToAdd = "pulse-blue";
        break;
      case 'orange':
      default:
        break;
    }
    var returnValues        = siteGlobals.variableElementTest(element);
    returnValues['success'] = false;
    element                 = returnValues['element'];

    //only if element is valid should we attempt to pulse it.
    if (returnValues['errors'].length === 0) {
      $(element).addClass(classToAdd);
      setTimeout(function() {
        $(element).removeClass(classToAdd)
      }, 1300);
    }
  },

  escapeHtml: function(textToEscape) {
    return $('<div/>').text(textToEscape).html();
  },

  singleRun: function(methodName, params) {

    if (typeof this.methods !== "object") {
      this.methods = {};
    }

    if (typeof this.methods[methodName] === "undefined" || this.methods[methodName] === null) {
      this.methods[methodName] = [params];
      return true;
    }


    if (siteGlobals.arrayContainsObject(this.methods[methodName], params)) {
      return false;
    }

    this.methods[methodName].push(params);
    return true;
  },

  arrayContainsObject: function(array, obj) {

    if (typeof array === "undefined") {
      return false;
    }
    var x = array.length;
    while (x--) {


      if (JSON.stringify(array[x]) === JSON.stringify(obj)) {
        return true;
      }
      // console.log("start");
      // console.log(JSON.stringify(array[x]));
      // console.log(JSON.stringify(obj));
    }
    return false;
  },

  /**
   * @call        => siteGlobals.isVisible(element);
   *
   * @date        => July 29, 2016
   *
   * @description =>  Returns true if css neither opacity or visibility settings of the element in question or
   *                  any of it's parents prevent the element from being seen. If this element or any parents
   *                  have an opacity of 0 or visibility is not set to visible then this function will return
   *                  false.
   *
   * @param        element (DOM ELEMENT)  --element to scroll user view to
   *
   * @return       boolean isVisible (BOOLEAN)  --true if element is visible otherwise false
   */
  isVisible: function(element) {
    var isVisible         = true;
    var tempValues        = siteGlobals.variableElementTest(element);
    tempValues['success'] = false;
    element               = tempValues['element'];
    var i                 = 0;

    if (parseInt(tempValues['errors'].length) === 0) {
      var $element = $(element);
      while (parseInt($element.length) === 1) {
        if (i > 0) {
          var overflow = String($element.css('overflow'));
          if (overflow === 'visible' || overflow === 'initial' || overflow === 'inherit') {
            $element = $element.parent();
            if ($element.get(0) === siteGlobals.$body.get(0)) {
              $element = [];
            }
            i++;
            continue;
          }
        }
        var opacity    = parseFloat($element.css('opacity'));
        var visibility = String($element.css('visibility'));
        var display    = String($element.css('display'));
        //noinspection JSValidateTypes
        var height     = parseInt($element.outerHeight(true));
        //noinspection JSValidateTypes
        var width      = parseInt($element.outerWidth(true));
        if (opacity === 0 ||
            opacity === '0' ||
            visibility === 'hidden' ||
            visibility === 'collapse' ||
            display === 'none' ||
            height === 0 ||
            width === 0) {
          isVisible = false;
          break;
        }
        else {
          if ($element.get(0) === siteGlobals.$body.get(0)) {
            $element = [];
          }
          else {
            $element = $element.parent();
          }
        }
        i++;
      }
    }
    else if (siteGlobals.consoleLog) {
      console.log(tempValues['errors']);
    }
    if (siteGlobals.consoleLog && tempValues['warnings'].length > 0) {
      console.log(tempValues['warnings']);
    }
    return isVisible;
  },

  /**
   * @call        => siteGlobals.variableElementTest(element);
   *
   * @date        => July 25, 2016
   *
   * @description => Completes a battery of tests to confirm or convert element into an element if at all possible
   *   and return results
   *
   * @param        element (DOM ELEMENT)  --variable that is hopefully a DOM ELEMENT or some sort of reference to
   *   one
   *
   * @return       Array returnValues (associative array)  -- group of named arrays and the final result of
   *   `element`
   *                      ['element']  (DOM ELEMENT if possible)  -- returning the final result of the element
   *                      ['errors']  (ARRAY of STRINGs)  -- list of errors that have occurred (hopefully empty)
   *                      ['warnings']  (ARRAY of STRINGs)  -- list of warnings that have occurred (hopefully
   *   empty,
   *   but not too bad if it isn't)
   */
  variableElementTest: function(element) {
    var returnValues         = [];
    returnValues['errors']   = [];
    returnValues['warnings'] = [];

    if (typeof element === "undefined") {
      returnValues['errors'].push('SH_ERROR: Element was left "undefined" but is required.');
    }
    else if (typeof element === "string") {
      var $temp = $(element);
      if ($temp.length > 0) {
        element = $temp.get(0);
      }
      else {
        returnValues['errors'].push('SH_ERROR: Element was typeof "string" and an attempt to convert to DOM Element failed.');
      }
    }
    else if (typeof element === "object") {
      if (element instanceof jQuery) {
        switch (element.length) {
          case 0:
            returnValues['errors'].push('SH_ERROR: Element was instanceof jQuery but had no valid DOM elements in its selection');
            break;
          case 1:
            element = element.get(0);
            break;
          default:
            element = element.get(0);
            returnValues['warnings'].push('SH_WARNING: Element was instanceof jQuery and had more than one DOM element the first in the array was used.');
            break;
        }
      }
      else if (!(element instanceof Element)) {
        returnValues['errors'].push('SH_ERROR: Element was an unknown object and was not converted to a DOM Element');
      }
    }
    else {
      returnValues['errors'].push('SH_ERROR: Element was an unknown type and was not converted to a DOM Element');
    }
    returnValues['element'] = element;
    return returnValues;
  },


  /**
   * @call        => siteGlobals.scrollToElement();
   *
   * @date        => June 17, 2016
   *
   * @description => Using Translate to animate the scroll this should be an optimized clean way to animate the
   *   scroll.
   *
   * @param        element (DOM ELEMENT)  --element to scroll user view to
   */
  scrollToElement: function(element, view) {
    history.scrollRestoration = "manual";
    var nav_compact_size      = 50;
    var nav_size              = 90;
    if (siteGlobals.$body.hasClass('cp')) {
      nav_compact_size = 0;
      nav_size         = 0;
    }
    var returnValues        = siteGlobals.variableElementTest(element);
    returnValues['success'] = false;
    element                 = returnValues['element'];
    var viewTest            = siteGlobals.variableElementTest(view);
    if (viewTest['errors'].length > 0) {
      if (siteGlobals.$body.hasClass('cp')) {
        view = $("#sh-cp-content").get(0);
      }
      else {
        view = false;
      }
    }
    //only if element is valid should we attempt to scroll to it.
    if (returnValues['errors'].length === 0) {
      var y = parseFloat($(element).offset().top);

      if (view !== false) {
        y -= parseFloat($(view).offset().top);
      }

      if (y > siteGlobals.$window.height()) {
        y -= nav_compact_size; // 50 is the height of the navbar at compact size in pixels.
      }
      else {
        y -= nav_size; // 90 is the height of the navbar at full size in pixels.
      }

      y = Math.ceil(y); // Don't want to end up between pixels.

      y -= 20; // Just to give some breathing room above the element we are scrolling to

      siteGlobals.scrollToX(y);
      returnValues['success'] = true;
    }
    else if (siteGlobals.consoleLog) {
      console.log(returnValues['errors']);
    }
    if (siteGlobals.consoleLog && returnValues['warnings'].length > 0) {
      console.log(returnValues['warnings']);
    }
    return returnValues;
  },


  /**
   * @call        => siteGlobals.scrollToX();
   *
   * @date        => June 17, 2016
   *
   * @description => Using Translate to animate the scroll this should be an optimized clean way to animate the
   *   scroll.
   *
   * @param        y (INTEGER)  --position to scroll user view to
   */
  scrollToX: function(y) {
    var $scrollable_content = siteGlobals.$site_page_content;
    if (y < 0) // don't attempt to scroll past the top of the page
    {
      y = 0;
    }
    if (siteGlobals.$body.hasClass('cp')) {
      $scrollable_content = $('#cp-main-scroll-element');
      $scrollable_content.animate({scrollTop: y}, 750);
      return;

    }
    siteGlobals.setPageScrollValues();
    if (y > siteGlobals.endOfPageScroll) // don't attempt to scroll past the bottom of the page
    {
      y = siteGlobals.endOfPageScroll;
    }
    var speed = 750;
    // how long the animation should take in milliseconds
    // This is a relatively short amount of time because the longer this animation goes the more likely a user is
    // to
    // attempt to scroll mid animation causing a choppy experience... As this finishes in less than a second
    // currently the user shouldn't notice any problems.
    var currentPosition = siteGlobals.$window.scrollTop();
    if (siteGlobals.$body.hasClass('cp')) {
      currentPosition = siteGlobals.$site_page_content.scrollTop();
    }
    // get the distance we want to animate our scroll over
    var distanceToTarget = -1 * (currentPosition - y);

    // move the page the amount we want to scroll (make the distance to target 0)
    $scrollable_content.css('transform', 'translateY(' + distanceToTarget + 'px)');

    // Instantly (read simultaneously completed with line above) we move our scroll position back to our current
    // position by using our old currentPosition and the distanceToTarget.
    // This line makes it seem like nothing has happened yet.
    if (siteGlobals.$body.hasClass('cp')) {
      siteGlobals.$site_page_content.scrollTop(distanceToTarget + currentPosition);
    }
    else {
      siteGlobals.$window.scrollTop(distanceToTarget + currentPosition);
    }
    // now we set the css transition that will allow our next move to take time rather than happen instantly
    $scrollable_content.css('transition', 'transform ' + speed + 'ms ease');
    setTimeout(
      function() {
        // after a short break for the css code to be applied to the DOM model we
        $scrollable_content.css('transform', 'translateY(0px)');
        setTimeout(
          function() {
            // after the animation is done we need to remove the transition so as to avoid problems if the
            // scroll to top button is clicked again. Removing the transform entirely just ensures that the page
            // is treated normally again.
            $scrollable_content.css('transition', '')
                               .css('transform', '');
          },
          (speed + 100) // This will wait until the animation ends to reset the transition css
          // (100ms extra just ensures that the animation is complete)
        )
      },
      100 // This will wait for the css to complete its update so the transition css is ready
      // Without this pause the page jumps immediately to the top without any animation
    )

  },

  /**
   * @call        => siteGlobals.animateAdd( element, parent );
   *
   * @date        => July 22, 2016
   *
   * @description => Takes a presumably associated input field and validation errors list and creates and displays
   *   the
   *   errors in an unordered list
   *
   * @param        element (DOM ELEMENT)  -- The element to add via animation
   * @param        parent (DOM ELEMENT) -- The element to add the `element` variable to
   * @param        after (BOOLEAN)  -- If true the `element` is added via '.after()' else it is added '.append()'
   *   to the `parent` element
   *
   * @param         prepend(BOOLEAN)  -- If true the `element` is added via '.prepend()' else it is added
   *   '.append()' to the `parent` element
   * @param        wrapped_content (BOOLEAN) -- If true adds a class to handle content being added to a
   *   wrapped space. Meaning the element should be inline-block and vertical-align top
   */
  animateAdd: function(element, parent, after, prepend, wrapped_content) {
    var speed = 500; // ms for the animation to complete and the delete to happen (must be at least
    // 150)
    var returnValues         = {};
    returnValues['success']  = false;
    returnValues['errors']   = [];
    returnValues['warnings'] = [];

    var tempValues           = siteGlobals.variableElementTest(element);
    returnValues['errors']   = returnValues['errors'].concat(tempValues['errors']);
    returnValues['warnings'] = returnValues['warnings'].concat(tempValues['warnings']);
    element                  = tempValues['element'];

    tempValues               = siteGlobals.variableElementTest(parent);
    returnValues['errors']   = returnValues['errors'].concat(tempValues['errors']);
    returnValues['warnings'] = returnValues['warnings'].concat(tempValues['warnings']);
    parent                   = tempValues['element'];

    if (parseInt(returnValues['errors'].length) === 0) {

      /** @type jQuery Object */
      var $element   = $(element);
      var $container = $("<div></div>").addClass('animate-add-container').append($element);
      if (typeof wrapped_content !== 'undefined' && wrapped_content) {
        $container.addClass('animate-inline');
      }
      siteGlobals.$js_construction_zone.append($container);
      //noinspection JSValidateTypes
      var height   = (-1 * $container.outerHeight(true)) + 'px';
      var stepTime = (speed - 150) / 2;
      $container.css('margin-bottom', height)
                .css('opacity', '0')
                .css('transition', 'margin, opacity')
                .css('transition-duration', stepTime + 'ms');
      if (typeof after !== 'undefined' && after) {
        $(parent).after($container);
      }
      else if (typeof prepend !== 'undefined' && prepend) {
        $(parent).prepend($container);
      }
      else {
        $(parent).append($container);
      }
      setTimeout(function() {
        $container.css('margin-bottom', '');
        setTimeout(function() {
          $container.css('opacity', '');
          setTimeout(function() {
            $element.insertBefore($container);
            $container.remove()
          }, stepTime + 50);
        }, stepTime + 50);
      }, 50);
    }
    else if (siteGlobals.consoleLog) {
      console.log(returnValues['errors']);
    }
    if (siteGlobals.consoleLog && returnValues['warnings'].length > 0) {
      console.log(returnValues['warnings']);
    }
    return returnValues;
  },

  /**
   * @call        => siteGlobals.animatexxxxRemove( element );
   *
   * @date        => June 22, 2016
   *
   * @description => Recieves an element that will be removed from the DOM and use some fancy magic to make it go
   *   away visually-quietly.
   *
   * @param        element -- The element to remove via animation
   *
   */
  animateRemove: function(element) {
    var speed = 500; // ms for the animation to complete and the delete to happen (must be at least
    // 100)
    var returnValues         = {};
    returnValues['success']  = false;
    returnValues['errors']   = [];
    returnValues['warnings'] = [];

    var tempValues           = siteGlobals.variableElementTest(element);
    returnValues['errors']   = returnValues['errors'].concat(tempValues['errors']);
    returnValues['warnings'] = returnValues['warnings'].concat(tempValues['warnings']);
    element                  = tempValues['element'];
    element.id               = "element-being-removed";

    if (parseInt(returnValues['errors'].length) === 0) {
      var $element = $(element);

      var stepTime = ((speed - 100) / 2);
      $element.css('transition', 'margin, opacity')
              .css('transition-duration', stepTime + 'ms')
              .css('overflow', 'hidden');
      setTimeout(function() {
          $element.css('opacity', '0');
          setTimeout(function() {
              //noinspection JSValidateTypes
              $element.css('margin-bottom', (-1 * $element.outerHeight()) + "px");
              setTimeout(function() {
                  $element.remove();

                },
                stepTime + 50
              );
            },
            stepTime + 50
          );
        },
        50 // wait for css transition to be parsed and ready to use
      );

    }
    else if (siteGlobals.consoleLog) {
      console.log(returnValues['errors']);
    }
    if (siteGlobals.consoleLog && returnValues['warnings'].length > 0) {
      console.log(returnValues['warnings']);
    }
    return returnValues;
  },


  instantHideVertical: function(element) {

    var returnValues         = {};
    returnValues['success']  = false;
    returnValues['errors']   = [];
    returnValues['warnings'] = [];

    var tempValues           = siteGlobals.variableElementTest(element);
    returnValues['errors']   = returnValues['errors'].concat(tempValues['errors']);
    returnValues['warnings'] = returnValues['warnings'].concat(tempValues['warnings']);
    element                  = tempValues['element'];

    if (parseInt(returnValues['errors'].length) === 0) {
      var $element = $(element);
      $element.css({
        'transition'    : 'all 0s ease 0s',
        'opacity'       : '0',
        'margin-bottom' : (-1 * $element.outerHeight()) + "px",
        'overflow'      : 'hidden',
        'pointer-events': 'none'
      });
    }
    else if (siteGlobals.consoleLog) {
      console.log(returnValues['errors']);
    }
    if (siteGlobals.consoleLog && returnValues['warnings'].length > 0) {
      console.log(returnValues['warnings']);
    }
    return returnValues;
  },

  animateHideVertical: function(element) {
    var speed = 1000; // ms for the animation to complete and the delete to happen (must be at least
                      // 100)
    var returnValues         = {};
    returnValues['success']  = false;
    returnValues['errors']   = [];
    returnValues['warnings'] = [];

    var tempValues           = siteGlobals.variableElementTest(element);
    returnValues['errors']   = returnValues['errors'].concat(tempValues['errors']);
    returnValues['warnings'] = returnValues['warnings'].concat(tempValues['warnings']);
    element                  = tempValues['element'];

    if (parseInt(returnValues['errors'].length) === 0) {
      var $element = $(element);

      var stepTime = ((speed - 100) / 2);
      $element.css({
        'transition'         : 'margin, opacity',
        'transition-duration': stepTime + 'ms',
        'overflow'           : 'hidden',
        'pointer-events'     : 'none'
      });
      setTimeout(function() {
          $element.css('opacity', '0');
          setTimeout(function() {
              //noinspection JSValidateTypes
              $element.css('margin-bottom', (-1 * $element.outerHeight()) + "px");
            },
            stepTime + 50
          );
        },
        50 // wait for css transition to be parsed and ready to use
      );
    }
    else if (siteGlobals.consoleLog) {
      console.log(returnValues['errors']);
    }
    if (siteGlobals.consoleLog && returnValues['warnings'].length > 0) {
      console.log(returnValues['warnings']);
    }
    return returnValues;
  },
  animateShowVertical: function(element) {
    var speed = 1000; // ms for the animation to complete and the delete to happen (must be at least
                      // 100)
    var returnValues         = {};
    returnValues['success']  = false;
    returnValues['errors']   = [];
    returnValues['warnings'] = [];

    var tempValues           = siteGlobals.variableElementTest(element);
    returnValues['errors']   = returnValues['errors'].concat(tempValues['errors']);
    returnValues['warnings'] = returnValues['warnings'].concat(tempValues['warnings']);
    element                  = tempValues['element'];

    if (parseInt(returnValues['errors'].length) === 0) {
      var $element = $(element);

      var stepTime = ((speed - 100) / 2);
      $element.css({
        'transition'         : 'margin, opacity',
        'transition-duration': stepTime + 'ms',
        'overflow'           : 'hidden',
        'pointer-events'     : 'auto'
      });
      setTimeout(function() {
          //noinspection JSValidateTypes
          $element.css('margin-bottom', "0px");
          setTimeout(function() {
              $element.css('opacity', '1');
            },
            stepTime + 50
          );
        },
        50 // wait for css transition to be parsed and ready to use
      );
    }
    else if (siteGlobals.consoleLog) {
      console.log(returnValues['errors']);
    }
    if (siteGlobals.consoleLog && returnValues['warnings'].length > 0) {
      console.log(returnValues['warnings']);
    }
    return returnValues;
  },
  /**
   * @call        => siteGlobals.animateShow( element );
   *
   * @date        => August 8, 2017
   *
   * @ description: an animation that shows a hidden element (see animateHide directly below) from the users view
   *
   * @param        element (DOM ELEMENT)  -- The element to show via animation
   */
  animateShow: function(element) {
    var returnValues         = {};
    returnValues['success']  = false;
    returnValues['errors']   = [];
    returnValues['warnings'] = [];

    var tempValues           = siteGlobals.variableElementTest(element);
    returnValues['errors']   = returnValues['errors'].concat(tempValues['errors']);
    returnValues['warnings'] = returnValues['warnings'].concat(tempValues['warnings']);
    element                  = tempValues['element'];
    element                  = $(element);
    var elementHTML          = element.clone().removeAttr('style');

    var parent = element.parent();


    if (!parent.hasClass('animateShowDiv')) {
      element.wrap("<div class='animateShowDiv'></div>");
      parent = element.parent();
    }

    element.remove();
    siteGlobals.animateAdd(elementHTML, parent);
    return returnValues;
  },
  /**
   * @call        => siteGlobals.animatexxxxxRemove( element );
   *
   * @date        => August 8, 2017
   *
   * @description => hides the given element from the users view
   *
   * @param        element -- The element to remove via animation
   *
   */
  animateHide: function(element) {
    var speed = 1000; // ms for the animation to complete and the delete to happen (must be at least
                      // 100)
    var returnValues         = {};
    returnValues['success']  = false;
    returnValues['errors']   = [];
    returnValues['warnings'] = [];

    var tempValues           = siteGlobals.variableElementTest(element);
    returnValues['errors']   = returnValues['errors'].concat(tempValues['errors']);
    returnValues['warnings'] = returnValues['warnings'].concat(tempValues['warnings']);
    element                  = tempValues['element'];

    if (parseInt(returnValues['errors'].length) === 0) {
      var $element = $(element);

      var stepTime = ((speed - 100) / 2);
      $element.css('transition', 'margin, opacity')
              .css('transition-duration', stepTime + 'ms')
              .css('overflow', 'hidden');
      setTimeout(function() {
          $element.css('opacity', '0');
          setTimeout(function() {
              //noinspection JSValidateTypes
              $element.css('margin-bottom', (-1 * $element.outerHeight()) + "px");

            },
            stepTime + 50
          );
        },
        50 // wait for css transition to be parsed and ready to use
      );

    }
    else if (siteGlobals.consoleLog) {
      console.log(returnValues['errors']);
    }
    if (siteGlobals.consoleLog && returnValues['warnings'].length > 0) {
      console.log(returnValues['warnings']);
    }
    return returnValues;
  },
  /**
   * @call        => siteGlobals.displayValidationErrors( input, errors );
   *
   * @date        => June 20, 2016
   *
   * @description => Takes a presumably associated input field and validation errors list and creates and displays
   *   the errors in an unordered list
   *
   * @param        input
   * @param        errors
   * @param        overrideType
   * @param        icon
   */
  displayValidationErrors: function(input, errors, overrideType, icon) {
    var returnValues         = {};
    returnValues['success']  = false;
    returnValues['errors']   = [];
    returnValues['warnings'] = [];

    var tempValues           = siteGlobals.variableElementTest(input);
    returnValues['errors']   = returnValues['errors'].concat(tempValues['errors']);
    returnValues['warnings'] = returnValues['warnings'].concat(tempValues['warnings']);
    input                    = tempValues['element'];

    if (parseInt(returnValues['errors'].length) === 0) {
      input = $(input);

      if (errors.length > 0) {
        var displayTarget = input.parent();
        var after         = input;
        var next          = input.next("label");
        if (displayTarget.hasClassStartingWith("col-")) {
          if (siteGlobals.$window.width() < 768 && displayTarget.hasClass('col-24')) {
            after = displayTarget;
          }
          else {
            displayTarget = displayTarget.parent();
            if (displayTarget.hasClass('row')) {
              after = displayTarget;
            }
          }
        }
        else if (displayTarget.hasClass('form-group')) {
          after = displayTarget;
        }
        else if (String(input.attr("type")) === "checkbox") {
          var formGroup = input.closest('.form-group');
          if (parseInt(formGroup.length) === 1) {
            after = formGroup;
          }
        }
        else if (parseInt(next.length) === 1) {
          after = next;
        }
        after = $(after);
        next  = after.next('.animate-add-container');
        if (next.length > 0) {
          while (next.next('.animate-add-container').length > 0) {
            next = next.next('.animate-add-container');
          }
          after = $(next);
        }
        var container_id   = input.attr('name') + '-errors';
        var move_left      = (after.hasClassStartingWith("col-") || after.hasClass('pull-left'));
        var errorContainer = siteGlobals.createErrorList(errors, container_id, move_left, overrideType, icon);

        // important... only add the error container if it's not already there! (no need to add it twice!)
        if (!after.parent().find("#" + container_id).length) {
          siteGlobals.animateAdd(errorContainer, after, true);
        }
      }
    }
    else if (siteGlobals.consoleLog) {
      console.log(returnValues['errors']);
    }
    if (siteGlobals.consoleLog && returnValues['warnings'].length > 0) {
      console.log(returnValues['warnings']);
    }
    return returnValues;
  },
  clearValidationErrors  : function(input) {
    var $container = $("#" + input.attr('name') + '-errors');
    if ($container.length > 0) {
      siteGlobals.animateRemove($container.get(0));
    }
  },
  createErrorList        : function(errors, container_id, move_left, type, icon) {
    if (typeof type === "undefined") {
      type = "danger";
    }
    if (typeof icon === "undefined") {
      icon = "exclamation-triangle";
    }
    if (typeof errors === 'undefined') {
      errors = [];
    }
    if (typeof move_left === 'undefined') {
      move_left = false;
    }
    var $errorContainer = $("#" + container_id);


    if ($errorContainer.length) {
      $errorContainer.html("");
    }
    else {
      $errorContainer = $("<div></div>");

      if (typeof errors === 'undefined') {
        errors = [];
      }


      if (typeof container_id !== 'undefined') {
        $errorContainer.attr('id', container_id);
      }
      if (move_left) {
        $errorContainer.addClass('pull-left').addClass('margin-horizontal');
      }
    }

    $errorContainer.removeClass("alert-danger").removeClass("alert-success").removeClass("alert-primary").removeClass("alert-info").addClass('alert')
                   .addClass('alert-' + type);

    var errorCount = errors.length;

    if (errorCount < 2) {
      $errorContainer.append("<i class='fa fa-" + icon + "'></i> " + errors[0]);
    }
    else {
      var $innerWrapper = $("<ul class='mb-0'></ul>");
      for (var i = 0; i < errorCount; i++) {
        var li = $("<li></li>").html(errors[i]);
        $innerWrapper.append(li);
      }
      $errorContainer.append($innerWrapper);
    }


    return $errorContainer;
  },


  bootstrapConfirm: function(title, message, buttonText, callback, timer, skipConfirm, afterClose, alwaysAfterClose, buttonType) {
    //noinspection JSJQueryEfficiency
    var $bootstrapConfirm = $("#bootstrapConfirmDialog");

    if (typeof skipConfirm === "undefined" || skipConfirm !== true) {
      skipConfirm = false;
    }
    if (typeof alwaysAfterClose === "undefined" || alwaysAfterClose !== true) {
      alwaysAfterClose = false;
    }
    if (typeof buttonType === "undefined") {
      buttonType = "success";
    }
    if (typeof callback !== "function") {
      callback = function() {

      };
    }
    if (typeof afterClose !== "function") {
      afterClose = function() {

      };
    }


    if (skipConfirm) {
      callback();
      afterClose();
      $bootstrapConfirm.off('hidden.bs.modal');

      return true;
    }


    if (!$bootstrapConfirm.length) {
      var confirmDialogHtml = "<div id='bootstrapConfirmDialog' class='modal fade sh-modal' data-backdrop='static' role='dialog'>" +
                              "<div class='modal-dialog'>" +
                              "<div class='modal-content'>" +
                              "<div class='modal-header'>" +
                              "<h4 id='bootstrapConfirmTitle' class='modal-title'></h4>" +
                              "<button type='button' class='close' data-dismiss='modal'>&times;</button>" +
                              "</div>" +
                              "<div id='bootstrapConfirmBody' class='modal-body'>" +
                              "" +
                              "</div>" +
                              "<div class='modal-footer'>" +
                              "<button type='button' class='btn btn-cancel btn-flat' data-dismiss='modal'>Cancel</button>" +
                              "<button type='button' class='btn btn-" +
                              buttonType +
                              "' data-dismiss='modal' id='bootstrapConfirmButton' style='white-space: nowrap'></button>" +
                              "</div>" +
                              "</div>" +
                              "</div>" +
                              "</div>";

      $("#app-layout").append(confirmDialogHtml);


      //noinspection JSJQueryEfficiency
      $bootstrapConfirm = $("#bootstrapConfirmDialog");
    }

    var $title  = $("#bootstrapConfirmTitle", $bootstrapConfirm);
    var $body   = $("#bootstrapConfirmBody", $bootstrapConfirm);
    var $button = $("#bootstrapConfirmButton", $bootstrapConfirm);


    $title.html(title);
    $body.html(message);
    $button.html(buttonText);

    if (typeof timer !== "undefined") {
      if (timer > 0) {
        $button.data("timer", timer);
        $button.append(" <span id='bootstrapConfirmDialogTimer'>(" + timer + ")</span>");
        $button.addClass("disabled");
        clearTimeout($button.data("timeout"));
        var thisTimeout = setTimeout(function() {
          siteGlobals.adjustBootstrapConfirmTimer($button);
        }, 1000);

        $button.data("timeout", thisTimeout);

      }
      else {
        $button.removeClass("disabled");
      }
    }

    $button.off();
    $button.click(function() {
      $bootstrapConfirm.modal("hide");
      callback();
      if (!alwaysAfterClose) {
        $bootstrapConfirm.on('hidden.bs.modal', function() {
          afterClose();
          $bootstrapConfirm.off('hidden.bs.modal');
        });
      }

    });
    $bootstrapConfirm.modal("show");

    if (alwaysAfterClose) {

      $bootstrapConfirm.off('hidden.bs.modal');
      $bootstrapConfirm.on('hidden.bs.modal', function() {
        afterClose();
        $bootstrapConfirm.off('hidden.bs.modal');
      });
    }

  },

  adjustBootstrapConfirmTimer: function($button) {

    var $timerEle    = $("#bootstrapConfirmDialogTimer");
    var currentTimer = $button.data("timer") - 1;
    $button.data("timer", currentTimer);
    $timerEle.html("(" + currentTimer + ")");
    if (currentTimer > 0) {

      clearTimeout($button.data("timeout"));
      var thisTimeout = setTimeout(function() {
        siteGlobals.adjustBootstrapConfirmTimer($button);
      }, 1000);
      $button.data("timeout", thisTimeout);
    }
    else {
      $timerEle.html("");
      $button.removeClass("disabled");
    }
  },
  shadeBackground            : function($ele, percent) {

    var color = $ele.css("background-color");

    var origColor = color;

    color      = color.substring(4);
    color      = color.slice(0, -1);
    var colors = color.split(",");

    var r = parseInt(colors[0]);
    var g = parseInt(colors[1]);
    var b = parseInt(colors[2]);

    var reverse = false;

    if (percent < 0) {
      r       = 255 - r;
      g       = 255 - g;
      b       = 255 - b;
      reverse = true;
      percent = percent * -1;
    }

    r -= parseInt(r * (percent / 100));
    g -= parseInt(g * (percent / 100));
    b -= parseInt(b * (percent / 100));

    r = (r < 0) ? 0 : r;
    g = (g < 0) ? 0 : g;
    b = (b < 0) ? 0 : b;

    if (reverse) {
      r = 255 - r;
      g = 255 - g;
      b = 255 - b;
    }

    var output = "rgb(" + r + ", " + g + ", " + b + ")";

    $ele.data("origbg", origColor);
    $ele.css("background-color", output);

  },

  /**
   * @call        => siteGlobals.ajaxForm();
   *
   * @date        => June 17, 2016
   *
   * @description => Handles setting up the csrf token for laravel, gathers all form inputs into a data variable,
   *                  and passes results from call back to function
   *
   * @param        form
   * @param        callback
   * @param        errorCallback
   * @param        extraData
   * @param        controller
   */
  ajaxForm: function(form, callback, errorCallback, extraData, controller) {
    controller = controller || false;

    var returnValues         = {};
    returnValues['success']  = false;
    returnValues['errors']   = [];
    returnValues['warnings'] = [];

    var tempValues           = siteGlobals.variableElementTest(form);
    returnValues['errors']   = returnValues['errors'].concat(tempValues['errors']);
    returnValues['warnings'] = returnValues['warnings'].concat(tempValues['warnings']);
    form                     = tempValues['element'];

    if (parseInt(returnValues['errors'].length) === 0) {
      var $form     = $(form).closest('form');
      var $inputs   = $form.find('input:not([type="button"]):not([type="reset"]):not([type="submit"])[name]:not([name=""]), select[name]:not([name=""]), textarea[name]:not([name=""])');
      var $files    = $inputs.filter('[type="file"]');
      var has_files = false;
      $files.each(function() {
        if (String($(this).val()) !== '') {
          has_files = true;
          return false;//break;
        }
      });
      var data;
      if (has_files) {
        data = new FormData();
        $inputs.each(function() {
          var $this = $(this);
          if ($this.attr("type") !== "radio" && $this.attr("type") !== "checkbox") {
            data.append($this.attr('name'), $this.val());
          }
          else if ($this.is(':checked')) {
            data.append($this.attr('name'), $this.val());
          }
        });
        $files.each(function() {
          $.each(
            this.files,
            function(i, file) {
              data.append("file_" + i, file);
            }
          );
        });
      }
      else {
        data = {};

        $inputs.each(function() {
          var $this = $(this);
          if ($this.attr("type") !== "radio" && $this.attr("type") !== "checkbox") {
            data[$this.attr('name')] = $this.val();
          }
          else if ($this.is(':checked')) {
            data[$this.attr('name')] = $this.val();
          }
        });
      }
      for (var dataProp in extraData) {
        // While looping over the properties of the extra data given to send with the form be certain that property
        // is actually an intentional property and that it is not already included in the form data. If either of
        // these statements is incorrect then ignore that property.
        if (extraData.hasOwnProperty(dataProp) && !data.hasOwnProperty(dataProp)) {
          data[dataProp] = extraData[dataProp];
        }
      }
      var defaultController = true;
      if (!controller) {
        var url    = $form.attr('action');
        controller = url.replace(/^.*\/\/[^\/]+/, '');
        var index  = controller.indexOf('/ajax');
        if (index !== -1) {
          controller = controller.slice(0, index);
        }
        controller = controller.replace(/^\//, '');
      }
      else {
        defaultController = false;
      }


      var storeReturn = siteGlobals.ajaxStore(controller, callback, data, errorCallback, defaultController, has_files);
      for (var returnProp in storeReturn) {
        if (storeReturn.hasOwnProperty(returnProp)) {
          returnValues['ajaxStore-' + returnProp] = storeReturn[returnProp];
        }
      }
      if (returnValues['ajaxStore-success']) {
        returnValues['success'] = true;
      }
    }
    else if (siteGlobals.consoleLog) {
      console.log(returnValues['errors']);
    }
    if (siteGlobals.consoleLog && returnValues['warnings'].length > 0) {
      console.log(returnValues['warnings']);
    }
    return returnValues;
  },

  /**
   * @call        => siteGlobals.ajaxStore();
   *
   * @date        => June 17, 2016
   *
   * @description => Handles setting up the csrf token for laravel and passes results from call back to function
   *
   * @param        controller
   * @param        callback
   * @param        data
   * @param        errorCallback
   * @param        defaultController
   * @param        has_files
   */
  ajaxStore: function(controller, callback, data, errorCallback, defaultController, has_files) {
    var returnValues        = {};
    returnValues['success'] = false;
    returnValues['errors']  = [];

    controller    = controller || false;
    data          = data || {};
    callback      = callback || function(response) {
      if (siteGlobals.consoleLog) {
        console.log(response);
      }
      return true;
    };
    errorCallback = errorCallback || function(response) {
      if (siteGlobals.consoleLog) {
        console.log(response);
      }
      return false;
    };

    if (!controller) {
      returnValues['errors'].push('SH_ERROR: Controller was left "undefined" but is required.');
    }
    if (typeof controller !== "string") {
      returnValues['errors'].push('SH_ERROR: typeof controller != "string"');
    }
    if (typeof data !== "object") {
      returnValues['errors'].push('SH_ERROR: typeof data != "object"');
    }
    if (typeof callback !== "function") {
      returnValues['errors'].push('SH_ERROR: typeof callback != "function"');
    }
    if (typeof errorCallback !== "function") {
      returnValues['errors'].push('SH_ERROR: typeof errorCallback != "function"');
    }
    if (!siteGlobals.csrfToken) {
      returnValues['errors'].push('SH_ERROR: CSRF Token is not set.');
    }

    if (defaultController) {
      controller = controller + '/ajax-store';
    }
    else {
      if (controller.toLowerCase().indexOf('update') !== -1 || controller.toLowerCase().indexOf('edit') !== -1) {
        data['_method'] = 'PATCH';
      }
    }
    //only if all parameters pass checks and the csrf token is set should we load
    {
      if (parseInt(returnValues['errors'].length) === 0) {
        var ajax_url = '/' + controller;
        if (controller.indexOf('http') !== -1) {
          ajax_url = controller;
        }
        if (has_files) {
          $.ajax({
            url        : ajax_url,
            method     : "POST",
            cache      : false,
            contentType: false,
            processData: false,
            data       : data,
            async      : true,
            beforeSend : function(xhr) {
              return xhr.setRequestHeader('X-CSRF-TOKEN', siteGlobals.csrfToken)
            },
            success    : function(response) {
              callback.call(siteGlobals, response);
            },
            error      : function(response) {
              if (parseInt(response.status) === 419) {
                response.responseText = jQuery.parseJSON(response.responseText);
              }
              if (parseInt(response.status) === 422) {
                response.responseText = jQuery.parseJSON(response.responseText);
              }
              errorCallback.call(siteGlobals, response);
            }
          });
          returnValues['success'] = true;
        }
        else {
          $.ajax({
            url       : ajax_url,
            method    : "POST",
            data      : data,
            async     : true,
            beforeSend: function(xhr) {
              return xhr.setRequestHeader('X-CSRF-TOKEN', siteGlobals.csrfToken)
            },
            success   : function(response) {
              callback.call(siteGlobals, response);
            },
            error     : function(response) {
              if (parseInt(response.status) === 419) {
                response.responseText = jQuery.parseJSON(response.responseText);
              }
              if (parseInt(response.status) === 422) {
                response.responseText = jQuery.parseJSON(response.responseText);
              }
              errorCallback.call(siteGlobals, response);
            }
          });
          returnValues['success'] = true;
        }
      }
      else {
        if (siteGlobals.consoleLog) {
          console.log(returnValues['errors']);
        }
      }
    }
    return returnValues;
  },

  /**
   * @call        => siteGlobals.ajaxLoad();
   *
   * @date        => June 15, 2016
   *
   * @description => Handles setting up the csrf token for laravel and passes results from call back to function
   *
   * @param        controller
   * @param        callback
   * @param        data
   * @param        errorCallback
   */
  ajaxLoad: function(controller, callback, data, errorCallback) {
    var returnValues        = {};
    returnValues['success'] = false;
    returnValues['errors']  = [];

    controller    = controller || false;
    data          = data || {};
    callback      = callback || function(response) {
      if (siteGlobals.consoleLog) {
        console.log(response);
      }
      return true;
    };
    errorCallback = errorCallback || function(response) {
      if (siteGlobals.consoleLog) {
        console.log(response);
      }
      return false;
    };

    if (!controller) {
      returnValues['errors'].push('SH_ERROR: Controller was left "undefined" but is required.');
    }
    if (typeof controller !== "string") {
      returnValues['errors'].push('SH_ERROR: typeof controller != "string"');
    }
    if (typeof data !== "object") {
      returnValues['errors'].push('SH_ERROR: typeof data != "object"');
    }
    if (typeof callback !== "function") {
      returnValues['errors'].push('SH_ERROR: typeof callback != "function"');
    }
    if (typeof errorCallback !== "function") {
      returnValues['errors'].push('SH_ERROR: typeof errorCallback != "function"');
    }
    if (!siteGlobals.csrfToken) {
      returnValues['errors'].push('SH_ERROR: CSRF Token is not set.');
    }

    //only if all parameters pass checks and the csrf token is set should we load
    if (parseInt(returnValues['errors'].length) === 0) {
      $.ajax({
        url       : '/' + controller + '/ajax-load',
        type      : "POST",
        data      : data,
        beforeSend: function(xhr) {
          return xhr.setRequestHeader('X-CSRF-TOKEN', siteGlobals.csrfToken)
        },
        success   : function(response) {
          callback.call(siteGlobals, response);
        },
        error     : function(response) {
          errorCallback.call(siteGlobals, response);
        }
      });
      returnValues['success'] = true;
    }
    else {
      if (siteGlobals.consoleLog) {
        console.log(returnValues['errors']);
      }
    }
    return returnValues;
  },

  resizeTextarea: function(textarea) {
    $(textarea).css("height", "5px");
    $(textarea).css("height", parseInt(textarea.scrollHeight) + "px");
    $(textarea).css("overflow", "hidden");
  },

  clearForm: function($form) {
    //get inputs
    var $inputs = $form.find('input:not([type="button"]):not([type="reset"]):not([type="submit"]), select, textarea');
    //clear all inputs
    $inputs.val('').prop('checked', false).filter('select').find('option:first-child').prop('selected', true);
    $inputs.find('[selected]').prop('selected', true);
    $inputs.filter('select.selectpicker').selectpicker('render').selectpicker('refresh');
    $inputs.filter('[checked]').prop('checked', true);
    $inputs.filter('[value]').each(function() {
      var $this = $(this);
      $this.val($this.attr('value'));
    });
  },

  setForm: function($form, value_object) {
    //get inputs
    var $inputs = $form.find('input:not([type="button"]):not([type="reset"]):not([type="submit"]), select, textarea');
    //loop over values and id_or_name pairs
    for (var id_or_name in value_object) {
      //make sure this id_or_name is not a property from javascript prototype objects
      if (!value_object.hasOwnProperty(id_or_name)) {
        continue;
      }
      //check if the id_or_name was an id
      var $input = $inputs.filter('#' + id_or_name);
      if ($input.length < 1) {
        //check if the id_or_name was an name
        $input = $inputs.filter('[name=' + id_or_name + ']');
        if ($input.length < 1) {
          //neither skip it...
          console.log('warning: field not found:  "' + id_or_name + '"');
          continue;
        }
      }
      // get the value for this id_or_name
      var value = value_object[id_or_name];
      //check what kind of input we are setting
      //select and textarea elements don't have a type but that's fine the default catches them and works just fine
      switch ($input.attr('type')) {
        //--------------------------
        case 'checkbox':
          if ($input.val() === value) {
            $input.attr('checked', true);
          }
          else {
            $input.attr('checked', false);
          }
          break;
        //--------------------------
        case 'radio':
          $input.filter('[value=' + value + ']').attr('checked', true);
          break;
        //--------------------------
        default:
          $input.val(value);
          break;
      }
      if ($input.is('select.selectpicker')) {
        $input.selectpicker('render').selectpicker('refresh');
      }
    }
  },

  setPageScrollValues: function() {
    var bodyHeight    = siteGlobals.$body.height() - siteGlobals.$window.height();
    var contentHeight = $("#sh-cp-content").height() - siteGlobals.$window.height();

    //never go below 0
    siteGlobals.endOfPageScroll = Math.max(0, bodyHeight, contentHeight);
  },

  /**
   * @call        => siteGlobals.reInitiate();
   *
   * @date        => June 15, 2016
   *
   * @description => Re-initializes values, jQuery access, and event handlers that may need to be updated.
   *                  This should be called if the window re-sizes or if the document height changes for any reason.
   *
   */
  reInitiate: function() {
    siteGlobals.setPageScrollValues();

    // Remove all elements with the 'rm-if-js' class these elements are necessary if javascript is not available.
    $('.rm-if-js')
      .remove();

    // All of the elements with 'js-enable' are essentially non-existent until the class is removed via javascript
    // These elements are only helpful if javascript is available so otherwise they are hidden.
    $('.js-enable')
      .removeClass('js-enable');

    $('[data-toggle="popover"]').popover({
      html   : true,
      trigger: "focus"
    });
  },

  anchorScroll: function(hash_id, force) {
    if (hash_id === "#") {
      return;
    }
    force = force || false;
    if (!force && hash_id === "#order-form") {
      return;
    }
    var $test_element = $(hash_id);
    if ($test_element.length < 1) {
      $test_element = $('[name="' + hash_id.substring(1) + '"]');
    }
    if ($test_element.length > 0) {
      var $scrollable_content = siteGlobals.$document;
      if (siteGlobals.$body.hasClass('cp')) {
        // $scrollable_content = $('#sh-cp-content');
        $scrollable_content = $('#cp-main-scroll-element');
      }
      $scrollable_content.each(function() {
        $(this).data("remember_scroll", $(this).scrollTop());
      });
      window.location.hash = hash_id;
      $scrollable_content.each(function() {
        $(this).scrollTop($(this).data("remember_scroll"));
      });
      siteGlobals.scrollToElement($test_element);
    }
  },

  /**
   * @call        => siteGlobals.initiate();
   *
   * @date        => June 15, 2016
   *
   * @description => Initializes starting values, saves jQuery access to local globals, and sets event handlers.
   *
   */
  initiate: function() {
    siteGlobals.values              = [];
    siteGlobals.values['csrf_life'] = blade_layout['csrf_life'];

    // Remove class 'no-js' from body so that css alterations can be made.
    $('#app-layout').removeClass('no-js');

    // Remove all elements with the 'rm-if-js' class these elements are necessary if javascript is not available.
    $('.rm-if-js').remove();

    // All the elements with 'js-enable' are essentially non-existent until the class is removed via javascript
    // These elements are only helpful if javascript is available so otherwise they are hidden.
    $('.js-enable').removeClass('js-enable');

    siteGlobals.$window                          = $(window);
    siteGlobals.$document                        = $(document);
    siteGlobals.$html                            = $('html');
    siteGlobals.$head                            = $('head');
    siteGlobals.$body                            = $('body');
    siteGlobals.$site_page_content               = $('#sh-page-content');
    siteGlobals.$js_construction_zone            = $('#js-construction-zone');
    siteGlobals.$toaster_notifications_container = $('#toaster-notifications-container');

    // siteGlobals.delayAddExtraIcons();

    siteGlobals.endOfPageScroll = siteGlobals.$body.height() - siteGlobals.$window.height();

    siteGlobals.url      = window.location.href;
    siteGlobals.url      = siteGlobals.url.split('?')[0];
    siteGlobals.base_url = window.location.protocol + '//' + window.location.hostname + '/';

    if (window.console) {
      if (typeof window.console.log === 'function') {
        siteGlobals.consoleLog = true;
      }
    }
    siteGlobals.csrfToken = $('meta[name="csrf-token"]').attr('content');

    /*****************************************************************************/
    // On Page Anchor Smoothness
    /*****************************************************************************/
    siteGlobals.$window.on('hashchange', function(e) {
      if (e.preventDefault) {
        e.preventDefault();
      }

      if (e.stopPropagation) {
        e.stopPropagation();
      }

      return false;// stop the page jump
    });

    siteGlobals.$document.on('hashchange', function(e) {
      if (e.preventDefault) {
        e.preventDefault();
      }

      if (e.stopPropagation) {
        e.stopPropagation();
      }

      return false;// stop the page jump
    });

    siteGlobals.$site_page_content.find('[href^="#"]:not([data-toggle]):not(.normal)').click(function(e) {
      if (e.preventDefault) {
        e.preventDefault();
      }
      if (e.stopPropagation) {
        e.stopPropagation();
      }
      var hash_id = $(e.target).closest('[href^="#"]');
      if (hash_id.length > 0) {
        hash_id = hash_id.attr('href');
        siteGlobals.anchorScroll(hash_id);
      }
      return false;// stop the page jump
    });

    if (location.hash) {               // do the test straight away
      history.scrollRestoration = "manual";
      siteGlobals.$window.scrollTop(0);         // execute it straight away
      setTimeout(function() {
        siteGlobals.$window.scrollTop(0);     // run it a bit later also for browser compatibility
        siteGlobals.anchorScroll(location.hash, true);
        if (location.hash === "#order-form") {
          focusElementPreventScroll($("#full_name"));
          // hmmm, something is stealing the focus after this. Seems nearly impossible to determine what though, so just try again in half a
          // second...
          setTimeout(function() {
            focusElementPreventScroll($("#full_name"));
          }, 500);
        }
      }, 1);
    }
    /*****************************************************************************/
    /*****************************************************************************/

    /*****************************************************************************/
    // Bootstrap Modal Initialization Fix
    /*****************************************************************************/
    siteGlobals.$body.on('shown.bs.modal', '.modal', function(e) {
      var $modal = $(e.target).closest('.modal');
      if ($modal.hasClass("data-grid-settings-modal")) {
        return true;
      }
      if ($modal.hasClass("sh-notification-modal")) {
        return true;
      }

      if ($modal.length > 0) {
        $modal.find('input:not([type="hidden"]):visible, select:visible, textarea:visible, button:visible:not([data-dismiss=modal])')
              .eq(0)
              .focus();

      }
    });

    /*****************************************************************************/
    /*****************************************************************************/

    /*****************************************************************************/
    // Popover adjustments and initialization
    /*****************************************************************************/
    Popper.Defaults.placement = "bottom";

    $('[data-toggle="popover"]').popover({
      html   : true,
      trigger: 'focus'
    });
    /*****************************************************************************/
    /*****************************************************************************/

    /*****************************************************************************/
    // CSS Modal Event that stops scrolling on body when the modal is open
    /*****************************************************************************/
    siteGlobals.$css_modal_checkboxes = $('input.css-modal-checkbox');
    if (siteGlobals.$css_modal_checkboxes.is(':checked')) {
      if (!siteGlobals.$body.hasClass('modal-open')) {
        siteGlobals.$body.addClass('modal-open');
      }
    }
    siteGlobals.$css_modal_checkboxes.on('input change blur keyup', function() {
      if (siteGlobals.$css_modal_checkboxes.is(':checked')) {
        if (!siteGlobals.$body.hasClass('modal-open')) {
          siteGlobals.$body.addClass('modal-open');
        }
        return true;
      }
      if (siteGlobals.$body.hasClass('modal-open')) {
        siteGlobals.$body.removeClass('modal-open');
      }
      return true;
    });

  }
};

siteGlobals.initiate();

$("html").scrollTop(0);

function serializedArrayToObject(array)
{
  var output = {};
  array.forEach(function(element) {
    if (typeof output[element.name] === "undefined") {
      output[element.name] = element.value;
    }
    else {
      if (typeof output[element.name] === "object") {
        output[element.name].push(element.value);
      }
      else {
        output[element.name] = [output[element.name], element.value];
      }

    }

  });

  return output;
}

$(function() {
  $("[data-toggle=collapse]").each(function() {
    if (($(this).attr("data-target") === "#" || $(this).attr("data-target") === "javascript:void(0);" ||
         !$($(this).attr("data-target")).hasClass("show")) &&
        ($(this).attr("href") === "#" || $(this).attr("href") === "javascript:void(0);" ||
         !$($(this).attr("href")).hasClass("show"))) {
      $(this).addClass("collapsed");
    }
  });
});

$("#approved-sender-convert-legacy-record-btn").click(function() {
  var sender = $("#approved-sender-settings-header").text();
  $("#approved-sender-add-new-button").click();
  $("#sender-address").val(sender);
});


var $kbSideBar = $(".kb-side-bar-scroll-wrapper");
$kbSideBar.find("a").off();
$kbSideBar.find("a").click(function(e) {

  var href = $(this).attr("href");

  if (typeof href === "undefined" || href.length < 1) {
    return true;
  }
  if (href.charAt(0) !== "#") {
    return true;
  }
  var topBarHeight = $("#cp-top-bar").height() + $(".kb-side-bar-fixed-header").height() + 20;

  e.preventDefault();
  $kbSideBar.scrollTop($(href).offset().top - topBarHeight);
  return false;
});

modalJs.class = "sh-modal";


$(".link-submit-form").click(function() {
  $(this).closest("form").submit();
});

$(".link-post").click(function() {
  var $form = $("<form>");
  $form.attr("METHOD", "POST").attr("action", $(this).attr("data-url"));
  var $csrfField = $("<input>");
  $csrfField.attr("name", "_token");
  $csrfField.attr("type", "hidden");
  $csrfField.attr("value", $('meta[name="csrf-token"]').attr('content'));
  $form.html($csrfField);
  $("body").append($form);
  $form.submit();

});

function jsFormatMoney(money)
{
  return parseFloat(money).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})
}

$(document).ready(function() {
  $("#start-free-trial-button-wrapper-pre-js").remove();
  $("#start-free-trial-button-wrapper").show();
  $(".enable-on-ready").prop("disabled", false);
});

//https://stackoverflow.com/a/6234804/4901390
function escapeHtml(unsafe)
{
  return unsafe
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
}

$("body").on("click", "#dismiss-adblocker-warning", function() {
  $.ajax({
    url        : "/dismiss-adblock-warning",
    method     : "POST",
    cache      : false,
    contentType: false,
    processData: false,
    async      : true,
    beforeSend : function(xhr) {
      return xhr.setRequestHeader('X-CSRF-TOKEN', siteGlobals.csrfToken)
    },
    success    : function(response) {
      window.adblockerWarningToast.set({time: 0});
    },
    error      : function(response) {
      // do nothing...
    }
  });
});

var tawkWrapped = false;

function wrapTawkWidget()
{
  var $tawkWidget = $(".widget-visible");
  if ($tawkWidget.length > 0) {
    var $wrapper = $("<div>");
    $wrapper.addClass("tawk-widget-wrapper");
    $tawkWidget.wrap($wrapper);
    tawkWrapped = true;
  }
  else {
    // try again in a second, up to 10 times
    if (typeof wrapTawkWidget.counter === "undefined") {
      wrapTawkWidget.counter = 0;
    }
    wrapTawkWidget.counter++;
    if (wrapTawkWidget.counter < 40) {
      setTimeout(wrapTawkWidget, 250);
    }
  }

}


wrapTawkWidget();

