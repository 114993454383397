var homePageBanner = {
  /**
   * Container to handle all paper-world container functions and items and interactions with the page/window
   */
  view: {
    $paperWorld   : null, // local view global to store jQuery access to the '#hpb-paper-world' element
    $window       : null, // local view global to store jQuery access to the window global
    width         : null, // local view global to cache the homePageBanner.view.$paperWorld.width()
    height        : null, // local view global to cache the homePageBanner.view.$paperWorld.height()
    scrollPosition: null, // local view global for the current user's scroll position
    scale         : null, // local view global that is the scale to be used on element created for paper-world
    isMobile      : null, // local view global boolean for whether or not it is mobile
    resizeTimeout : null, // local view global variable to store a timeout if there is currently one
    initiated     : false, // local view global boolean for whether or not it has been initiated once before

    /**
     * @call        => homePageBanner.view.mobileAndTabletcheck();
     *
     * @date        => May 17, 2016
     *
     * @description => Function taken directly and unaltered from http://stackoverflow.com/a/11381730/4059832
     *                  Essentially this performs a huge regex check on a few browser global variables to
     *                  determine if the device or rather the browser is a mobile client
     *
     */
    mobileAndTabletcheck: function() {
      // Initially we were checking if the device was a tablet... this isn't used any more but the function might be
      // called still... var check = false; (function ( a ) { if (
      // /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge
      // |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm(
      // os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows
      // ce|xda|xiino|android|ipad|playbook|silk/i.test( a ) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a
      // wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s
      // )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1
      // u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-|
      // |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt(
      // |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg(
      // g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-|
      // |o|v)|zz)|mt(50|p1|v
      // )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v
      // )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-|
      // )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test( a.substr( 0, 4 ) ) ) { check = true } })( navigator.userAgent ||
      // navigator.vendor || window.opera ); return check;
      return false;
    },

    /**
     * @call        => homePageBanner.view.randNum();
     *
     * @date        => May 11, 2016
     *
     * @description => Simple random number generator parsed to only 2 decimal places for speed optimization
     *                  in browser renderings and after that numbers that small aren't too meaningful
     *                  in this context.
     *
     * @param        min (NUMBER) --minimum allowed output
     * @param        max (NUMBER) --maximum allowed output
     */
    randNum: function(min, max) {
      // if min is not passed in it is set to 0
      min = typeof min !== 'undefined' ? min : 0;
      // if max is not passed in it is set to 1
      max = typeof max !== 'undefined' ? max : 1;

      return parseFloat(Math.random() * (max - min) + min)
        .toFixed(2);
    },

    /**
     * @call        => homePageBanner.view.initiate();
     *
     * @date        => May 10, 2016
     *
     * @description => Initializes starting view values, saves jQuery access to local view globals, and sets view event
     *   handlers.
     *
     */
    initiate: function() {
      homePageBanner.view.isMobile    = homePageBanner.view.mobileAndTabletcheck();
      homePageBanner.view.$window     = $(window);
      homePageBanner.view.$paperWorld = $("#hpb-paper-world");
      homePageBanner.view.width       = homePageBanner.view.$paperWorld.width();
      homePageBanner.view.height      = homePageBanner.view.$paperWorld.height();
      if (!homePageBanner.view.isMobile) {
        homePageBanner.view.scrollPosition = homePageBanner.view.$window.scrollTop();
      }

      //scale is used to scale down elements such as the clouds for smaller devices
      homePageBanner.view.scale = Math.min(parseFloat(homePageBanner.view.width / 1500), 1.0).toFixed(2);
      homePageBanner.view.scale = Math.max(parseFloat(homePageBanner.view.scale), 0.4).toFixed(2);// prevent scaling
                                                                                                  // below 40%;
      if (!homePageBanner.view.initiated) {
        homePageBanner.view.$window.resize(function() {
          if (homePageBanner.view.resizeTimeout != null) {
            // clear the timeout, if one is pending
            clearTimeout(parseInt(homePageBanner.view.resizeTimeout));
            homePageBanner.view.resizeTimeout = null;
          }
          homePageBanner.view.resizeTimeout =
            setTimeout(
              function() {
                homePageBanner.initiate();
              },
              2000
            );
        });
      }

    }
  },

  /**
   * Container to handle all paper-world city functions and items
   */
  city: {
    $cityLevels       : null, // local city global to store jQuery access to the ".hpb-city-depth-level" elements
    numberOfCityLevels: null, // local city global to store the length of the homePageBanner.city.$cityLevels array
    cityScrollTimeout : null, // local city global for any pending cityScrollTimeout

    /**
     * @call        => homePageBanner.city.distributeCityLevels();
     *
     * @date        => May 12, 2016
     *
     * @description => Calculates and distributes the city levels at depths according to the current scroll position
     *                  Further description details can be found next to each line of the function.
     *
     */
    distributeCityLevels: function() {
      var $cityLevels = homePageBanner.city.$cityLevels;
      var ii          = homePageBanner.city.numberOfCityLevels;

      // baseLevel determines which city level depth gets the baseOffset amount set this to
      // 0 for the front most level
      var baseLevel = 0;
      // baseOffset becomes the offset percentage amount for the baseLevel city level depth
      // all other city level depths move in relation to this offset
      var baseOffset = -55;
      // maxSpread (could be called the initialSpread)
      // is the amount in percentage between the farthest back city level and the
      // closest to the front city level when the screen is scrolled to the top of the window
      var maxSpread = 115;
      // using the #paper-world height as our window gives us more flexibility
      // in the allowed heights of our homePageBanner
      var height         = homePageBanner.view.height;
      // the current scroll position gives us the idea of a moving camera[AKA perspective]
      var scrollPosition = homePageBanner.view.scrollPosition;

      //******************************
      // Begin Calculations

      // currentPerspective is the angle we are viewing the city as a whole at
      // this is a percentage based variable on the height of the banner and
      // the current scroll position
      // Math.max is used with 0.1 to ensure that the perspective does not get below  10%
      // Math.max is used with 1.0 to ensure that the perspective does not get above 100%
      //    This both avoids animating the city after the banner is out of view as well as
      //        preventing the depth levels to be too close to each other or
      //        spread out farther than our maxSpread amount
      var currentPerspective = Math.min(Math.max(((height - scrollPosition) / height), 0.1), 1.0);

      // This defines the current distance from the first level of the city to the last level
      // AKA the spread of all city levels
      var currentSpread = currentPerspective * maxSpread;

      // Based on how many levels of the city there are and how many
      var depthBetweenLevels = currentSpread / (ii - baseLevel);

      //loop through the ii city levels and set their individual positions
      $cityLevels.each(function(i) {
        // ii - i gets the current index of the city level and determines what level and
        // therefore how many depthBetweenLevels needs to be used
        var cityLevelOffset = baseOffset + currentSpread - depthBetweenLevels * (ii - i);

        // ******
        // hack fix for front city level as it has so many short buildings
        if (i == 0) {
          cityLevelOffset += 35;
        }
        // ******

        //shed off extra decimals to enhance rendering performance
        cityLevelOffset = parseFloat(cityLevelOffset)
          .toFixed(2);

        // 'this' is the current city level as defined by the jQuery .each() method
        $(this)
          .css('transform', 'translateY(' + cityLevelOffset * -1 + '%)');
      });
    },

    /**
     * @call        => homePageBanner.city.initiate();
     *
     * @date        => May 12, 2016
     *
     * @description => Initializes starting city values, saves jQuery access to local city globals,
     *                  and sets event handlers that relate to the paper-world sky. Currently if
     *                  it detects that the device is mobile then it will not have city
     *                  levels move on scroll.
     *
     */
    initiate: function() {
      //The $cityLevels array is initiated in reverse standard order for better code comprehension.
      //This is done so that the city level in front is the first in the array
      //and the city level in the back is at the end of the array.
      homePageBanner.city.$cityLevels        = $(homePageBanner.view.$paperWorld.find(".hpb-city-depth-level")
                                                               .get()
                                                               .reverse());
      homePageBanner.city.numberOfCityLevels = homePageBanner.city.$cityLevels.length;

      if (!homePageBanner.view.isMobile) {
        //update the scroll position on load / refresh / re-initiation
        homePageBanner.view.scrollPosition = homePageBanner.view.$window.scrollTop();

        if (!homePageBanner.view.initiated) {

          homePageBanner.view.$window.scroll(
            function() {
              if (homePageBanner.city.cityScrollTimeout != null) {
                // clear the timeout, if one is pending
                clearTimeout(parseInt(homePageBanner.city.cityScrollTimeout));
                homePageBanner.city.cityScrollTimeout = null;
              }
              homePageBanner.city.cityScrollTimeout =
                setTimeout(
                  function() {
                    homePageBanner.view.scrollPosition = homePageBanner.view.$window.scrollTop();
                    homePageBanner.city.distributeCityLevels();
                  },
                  50 // super short timeout
                  // If this is longer the animation takes too long to play
                  // This short time is still helping clean up the dozens of useless scroll events firing
                );

            }
          );
        }
      }

      homePageBanner.city.distributeCityLevels();

    }
  },

  /**
   * Container to handle all paper-world sky functions and items
   */
  sky: {
    $sky     : null, //local sky global to store jQuery access to the '#hpb-sky' element
    $clouds  : null, //local sky global to store jQuery access to the '.hpb-cloud' elements
    lastCloud: null, //local sky global to store the index of the last cloud cluster used

    //local sky global to store the pre-selected css variable options for acceptable cloud formations
    cloudAttrOptions: [
      [
        {left: 150, top: -5, scale: 0.85},
        {left: 0, top: 50, scale: 0.7},
        {left: 175, top: 200, scale: 0.45}
      ],
      [
        {left: 150, top: -5, scale: 0.85},
        {left: 0, top: 50, scale: 0.7}
      ],
      [
        {left: 0, top: 150, scale: 0.55},
        {left: 150, top: 200, scale: 1.0}
      ],
      [{left: 0, top: 10, scale: 0.75}],
      [
        {left: 0, top: 60, scale: 0.5}
      ],
      [{left: 0, top: 80, scale: 1.0}],
      [
        {left: 100, top: 180, scale: 0.65},
        {left: 0, top: 200, scale: 0.55}
      ],
      [
        {left: 200, top: 180, scale: 0.85},
        {left: 0, top: 10, scale: 0.55}
      ],
      [
        {left: 0, top: 50, scale: 0.9},
        {left: 100, top: 120, scale: 0.65}
      ]
    ],

    cloudOptions_ii: null, //local sky global to store the length of the homePageBanner.sky.cloudAttrOptions array

    /**
     * @call        => homePageBanner.sky.updateCloudSelection();
     *
     * @date        => May 14, 2016
     *
     * @description => Reruns the jQuery selection to find all clouds in the DOM tree. Doing this does two important
     *                  things for us. First is that this makes certain that all clouds are available for other
     *                  functions. Secondly it removes the saved reference to any clouds that have been
     *                  removed from the DOM tree, which should effectively remove the element from
     *                  RAM destroying it entirely.
     *
     */
    updateCloudSelection: function() {
      homePageBanner.sky.$clouds = homePageBanner.sky.$sky.find('.hpb-cloud');
    },

    /**
     * @call        => homePageBanner.sky.getCloudIndex();
     *
     * @date        => June 3, 2016
     *
     * @description => Finds a cloud cluster index that is valid and not the same as the last one used
     *
     * @return      number index (INTEGER) a valid index for use on the homePageBanner.sky.cloudAttrOptions array
     */
    getCloudIndex: function() {
      var index = 0;
      do {
        index = Math.floor(homePageBanner.view.randNum(0, homePageBanner.sky.cloudOptions_ii - 1));
        if (index >= homePageBanner.sky.cloudOptions_ii) {
          index = homePageBanner.sky.cloudOptions_ii - 1;
        }
      }while (homePageBanner.sky.lastCloud != null && index == homePageBanner.sky.lastCloud);

      return index;
    },


    /**
     * @call        => homePageBanner.sky.getCloudIndex();
     *
     * @date        => June 3, 2016
     *
     * @description => Finds a cloud cluster index that is valid and not the same as the last one used
     *
     * @return      number index (INTEGER) a valid index for use on the homePageBanner.sky.cloudAttrOptions array
     */
    getCloudIndex: function() {
      var index = 0;
      do {
        index = Math.floor(homePageBanner.view.randNum(0, homePageBanner.sky.cloudOptions_ii - 1));
        if (index >= homePageBanner.sky.cloudOptions_ii) {
          index = homePageBanner.sky.cloudOptions_ii - 1;
        }
      }while (homePageBanner.sky.lastCloud != null && index == homePageBanner.sky.lastCloud);

      return index;
    },


    /**
     * @call        => homePageBanner.sky.createCloudCluster();
     *
     * @date        => May 14, 2016
     *    @edit        => June 3, 2016
     *                    Move index finding algorithm to separate function
     *
     * @description => Selects a cloud cluster to use if the index is not already provided and then proceeds to create
     *                 that cluster of clouds as HTML elements in the null namespace URI before adding each to the
     *                 DOM tree on the page. After they are all created, homePageBanner.sky.$clouds is then
     *                 updated to have them in its selection.
     *
     * @param        index (INTEGER) -- refers to the index of the desired cloud cluster to create
     * @param        extraLeft (INTEGER) -- refers to how far from the left this cloud cluster should start
     */
    createCloudCluster: function(index, extraLeft) {
      if (homePageBanner.sky.$clouds.length > 0 && typeof extraLeft == 'undefined') {
        if (homePageBanner.sky.$clouds.last().offset()['left'] < -100 * homePageBanner.view.scale) {
          return 0;
        }
      }
      if (homePageBanner.sky.$clouds.length < 12) {
        // if index is not passed in get a valid index
        index     = typeof index !== 'undefined' ? index : homePageBanner.sky.getCloudIndex();
        // if extraLeft is not passed in it is set to 0
        extraLeft = typeof extraLeft !== 'undefined' ? extraLeft : -500;

        homePageBanner.sky.lastCloud = index;
        var topOffset                = homePageBanner.view.randNum(-10, 25);
        var selectedCloudOption      = homePageBanner.sky.cloudAttrOptions[index];
        var ii                       = homePageBanner.sky.cloudAttrOptions[index].length;
        for (var i = 0; i < ii; i++) {
          var cloudAttr      = selectedCloudOption[i];
          var scale          = parseFloat(cloudAttr['scale'] * homePageBanner.view.scale)
            .toFixed(2);
          var top            = 'calc(' + topOffset + '% + ' + cloudAttr['top'] * homePageBanner.view.scale + 'px)';
          var left           = extraLeft + cloudAttr['left'];
          var $newCloudImage = $('<div></div>')
            .addClass('hpb-cloud-img')
            .css('transform', 'scale(' + scale + ',' + scale + ')');
          var $newCloud      = $('<div></div>')
            .addClass('hpb-cloud')
            .addClass('hpb-stopped')
            .css('top', top)
            .css('left', left + 'px')
            .append($newCloudImage);
          homePageBanner.sky.$sky.append($newCloud);
        }

        homePageBanner.sky.updateCloudSelection();
      }

    },

    /**
     * @call        => homePageBanner.sky.moveClouds();
     *
     * @date        => May 13, 2016
     *    @edit        => May 15, 2016
     *                    Change from jQuery animate left to css translate add rotateY for optimization
     *
     * @description => Selects all clouds with .hpb-stopped class and simply adds
     *                 the following css:
     *                 'transform: translate(100000px, 0px) rotateY(0deg);'
     *                 The translate is done to send the clouds on
     *                 their way and the rotateY is done for
     *                 repaint optimization purposes. Then
     *                 we proceeds to remove the class.
     *
     */
    moveClouds: function() {
      homePageBanner.sky.$clouds.filter('.hpb-stopped')
                    .css('transform', "translate(10000px ,0px) rotateY(0deg)");
      homePageBanner.sky.$clouds.removeClass('hpb-stopped');
    },

    /**
     * @call        => homePageBanner.sky.destroyClouds();
     *
     * @date        => May 13, 2016
     *
     * @description => Looping over all the clouds that are moving this finds clouds that have gone
     *                  past the right side of the paper-world and deletes them from the DOM and
     *                  should clear them from the RAM.
     *
     */
    destroyClouds: function() {
      // checks how far from the left a cloud is and marks it for deletion
      // if it is farther from the left than the width of the screen
      homePageBanner.sky.$clouds.filter(':not(.hpb-stopped)')
                    .each(function() {
                      // "+ 10" is just for small browser rendering differences to be sure the cloud is all
                      // the way off the screen before being deleted
                      var width = homePageBanner.view.width + 10;
                      var left  = $(this)
                        .offset().left;
                      if (left > width) {
                        $(this)
                          .addClass('hpb-destroy');
                      }
                    });
      // deletes all clouds marked for destruction
      $(".hpb-destroy")
        .remove();
      homePageBanner.sky.updateCloudSelection();
    },

    /**
     * @call        => homePageBanner.sky.cloudIntervalHandler();
     *
     * @date        => May 13, 2016
     *
     * @description => This calls a list of functions in the proper order to maintain the clouds
     *                  in the sky. First it starts previously created clouds on their way
     *                  across the screen. Then it destroys clouds that have moved past
     *                  the right edge of the screen. Finally it creates new clouds
     *                  that will wait until the next interval to start moving.
     *
     */
    cloudIntervalHandler: function() {
      homePageBanner.sky.moveClouds();
      homePageBanner.sky.destroyClouds();
      homePageBanner.sky.createCloudCluster();
    },

    /**
     * @call        => homePageBanner.sky.createInitialClouds();
     *
     * @date        => May 13, 2016
     *
     * @description => This calls homePageBanner.sky.createCloudCluster() placing space between
     *                  each cloud cluster for the initial look and layout of the clouds.
     *
     */
    createInitialClouds: function() {
      var leftIncrement = 500 * homePageBanner.view.scale;
      for (var i = 0; i < homePageBanner.sky.cloudOptions_ii; i++) {
        var extraLeft = (i * leftIncrement) - 250;
        homePageBanner.sky.createCloudCluster(undefined, extraLeft);
      }
    },

    /**
     * @call        => homePageBanner.sky.initiate();
     *
     * @date        => May 13, 2016
     *    @edit        => June 3, 2016
     *                    Added a check to make sure the background image is loaded which will ensure
     *                    that the css transition is ready to slow down (aka transition) the
     *                    translate that happens from the homePageBanner.sky.moveClouds()
     *                    otherwise the clouds are moved instantly 100000px or so over
     *                    and the homePageBanner.sky.destroyClouds() immediately
     *                    destroys them and cleans the RAM of their existence
     *
     * @description => Initializes starting sky values, saves jQuery access to local sky globals,
     *                  and sets event handlers that relate to the paper-world sky. Currently if
     *                  it detects that the device is mobile then it will not have the clouds
     *                  move and hence not create any clouds either.
     *
     */
    initiate: function() {
      homePageBanner.sky.cloudOptions_ii = homePageBanner.sky.cloudAttrOptions.length;
      homePageBanner.sky.$sky            = homePageBanner.view.$paperWorld.find('#hpb-sky');

      homePageBanner.sky.updateCloudSelection();
      homePageBanner.sky.$clouds.remove();

      homePageBanner.sky.createInitialClouds();

      // the following structure allows us to wait for the css to prep the transition so the
      // clouds from homePageBanner.sky.createInitialClouds() aren't just destroyed
      var backgroundToWaitOn = homePageBanner.sky.$clouds.find(".hpb-cloud-img")
                                             .css('background-image');
      if (backgroundToWaitOn) {
        var source = backgroundToWaitOn.replace(/(^url\()|(\)$|["'])/g, '');
        /*var $temporaryImg = */
        $('<img />')
          .attr('src', source)
          .on("load", function() {
            homePageBanner.sky.destroyClouds(); // check right away to clear any unwanted clouds

            if (!homePageBanner.view.isMobile) // if this is a mobile device don't do anything fancy
            {
              homePageBanner.sky.cloudIntervalHandler();

              setInterval(
                function() {
                  homePageBanner.sky.cloudIntervalHandler();
                },
                (2000 * homePageBanner.view.scale) // 1 minute and 2 seconds between cloud creation batches
                // the 2 seconds adds just enough space between clouds
              );
            }
          });
      }
    }
  },
  /**
   * @call        => homePageBanner.initiate();
   *
   * @date        => May 10, 2016
   *
   * @description => Initializes starting values, saves jQuery access to local globals, and sets event handlers.
   *
   */
  initiate: function() {
    homePageBanner.view.initiate();
    homePageBanner.city.initiate();
    homePageBanner.sky.initiate();
    homePageBanner.view.initiated = true;
  }
};


homePageBanner.initiate();

