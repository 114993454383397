var cookie_notice = {
  $section: null,

  initialize: function() {
    cookie_notice.$section = $('#site-wide-cookie-notice');
    setTimeout(function() {
      cookie_notice.$section.removeClass('animate-notice');
      setTimeout(function() {
        cookie_notice.$section.addClass('animate-close');
      }, 1250);
    }, 750);
    if (cookie_notice.$section.length >= 1) {
      $("#dismiss-site-wide-cookie-notice-button").click(
        function() {
          $.ajax({
            url       : blade_layout['cookie_notice_url'],
            method    : "POST",
            async     : true,
            beforeSend: function(xhr) {
              return xhr.setRequestHeader('X-CSRF-TOKEN', siteGlobals.csrfToken)
            },
            success   : function(response) {
              cookie_notice.$section.css('bottom', '-50px');
              setTimeout(function() {
                cookie_notice.$section.remove();
              }, 350);
            }
          });
        }
      )
    }
  }
};
cookie_notice.initialize();

