$("#kb-side-bar-hide-button").click(function() {
  $('#kb-side-bar').hide();
  $('.show-kb-side-bar').css({display: "inline-block"});
  $("#cp-main-scroll-element").show();
  dataGridHelper.stickyHeaderResizeFix();
});


$(".show-kb-side-bar").click(function() {
  $("#cp-main-scroll-element").hide();
  $('#kb-side-bar').show();
  $('.show-kb-side-bar').hide();
  siteGlobals.scrollToKb();
  dataGridHelper.stickyHeaderResizeFix();
});

$(".kb-link").click(function(e) {
  var kbId = parseInt($(this).attr("data-kb"));
  if (kbId < 1) {
    return true;
  }
  var $article = $("#kb-article-" + kbId);
  if (!$article.length) {
    return true;
  }

  e.preventDefault();
  $("#cp-main-scroll-element").hide();
  $('#kb-side-bar').show();
  $('.show-kb-side-bar').hide();
  siteGlobals.scrollToKb(kbId);
});

