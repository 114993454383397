/**
 * Created by Skeets on 5/18/2017.
 */
var $passwordStrengthMeter = $(".passwordStrengthMeter");
if ($passwordStrengthMeter.length) {

  var $passwordInput = $("input[name=new_password]");
  
  if (!$passwordInput.length) {
    $passwordInput = $("input[name=password]");
  }


  // code adapted from: https://bootsnipp.com/snippets/featured/password-strength-popover (MIT licence)
  //minimum 8 characters
  var passwordMeter_bad    = /(?=.{8,}).*/;
  //Alpha Numeric plus minimum 8
  var passwordMeter_good   = /^(?=\S*?[a-z])(?=\S*?[0-9])\S{8,}$/;
  //Must contain at least one upper case letter, one lower case letter and (one number OR one special char).
  var passwordMeter_better = /^(?=\S*?[A-Z])(?=\S*?[a-z])((?=\S*?[0-9])|(?=\S*?[\W\_]))\S{8,}$/;
  //Must contain at least one upper case letter, one lower case letter and (one number AND one special char).
  var passwordMeter_best   = /^(?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9])(?=\S*?[\W\_])\S{8,}$/;

  $passwordInput.on('input change blur keyup',function() {

    var strength = 'Weak';
    var pclass   = 'danger';

    var pass = $passwordInput.val();
    if (passwordMeter_best.test(pass) == true) {
      strength = 'Very strong';
      // Never hide the password error: esp:291858
      // $("[data-error-bag-for=password]").hide();
      pclass = 'success';
    }
    else if (passwordMeter_better.test(pass) == true) {
      strength = 'Almost strong';
      pclass   = 'warning';
      $("[data-error-bag-for=password]").show();
    }
    else if (passwordMeter_good.test(pass) == true) {
      strength = 'Almost strong';
      pclass   = 'warning';
      $("[data-error-bag-for=password]").show();
    }
    else if (passwordMeter_bad.test(pass) == true) {
      strength = 'Weak';
      $("[data-error-bag-for=password]").show();
    }
    else {
      strength = 'Very weak';
      $("[data-error-bag-for=password]").show();
    }


    $passwordStrengthMeter.html(strength);
    $passwordStrengthMeter.addClass("alert");
    $passwordStrengthMeter.removeClass("alert-success");
    $passwordStrengthMeter.removeClass("alert-warning");
    $passwordStrengthMeter.removeClass("alert-danger");
    $passwordStrengthMeter.removeClass("alert-info");
    $passwordStrengthMeter.addClass("alert-" + pclass);

    if (pass.length === 0) {
      $passwordStrengthMeter.hide();
    }
    else {
      $passwordStrengthMeter.show();
    }

  });
}

