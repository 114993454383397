var plan_selector = {
  $plan_buttons          : null,
  $plan_selector         : null,
  $plan_pop_up           : null,
  $selector_to_be_set    : null, // useful for when multiple plan selectors exist at once
  $renewal_rate_container: null,
  $renewal_price         : null,
  $annual_monthly        : null,

  planPopUpSetSelected: function() {
    plan_selector.$plan_buttons.text('SELECT')
                 .removeClass('selected btn-info')
                 .addClass('btn-inverse inverse-blue')
      //fine tune selection from all buttons to individual selected button
                 .filter('[data-plan-code=' +
                         plan_selector.$selector_to_be_set.filter('select').val() +
                         ']')
                 .addClass('selected btn-info')
                 .removeClass('btn-inverse inverse-blue')
                 .text('SELECTED');

  },

  planPopUpOpen: function(e) {
    if (typeof e !== 'undefined') {
      if (e.stopPropagation) {
        e.stopPropagation()
      }
      ;
    }
    plan_selector.planPopUpSetSelected();
    plan_selector.$plan_pop_up.addClass('open');
    siteGlobals.$body.addClass('modal-open');
  },

  planPopUpClose: function() {
    plan_selector.$plan_pop_up.removeClass('open');
    siteGlobals.$body.removeClass('modal-open');
    setTimeout(function() {
      plan_selector.$plan_pop_up.scrollTop(0);
    }, 300);
  },

  planPopUpSelected                 : function($button) {
    if ($button instanceof jQuery && $button.length === 1) {
      plan_selector.$selector_to_be_set.filter('select').selectpicker('val', $button.attr('data-plan-code'))
                   .trigger('change').next('.bootstrap-select').find('button.selectpicker').focus();
      setTimeout(function() {
        plan_selector.planPopUpClose();
        setTimeout(function() {
          siteGlobals.pulseElement(plan_selector.$selector_to_be_set.filter('select').next('.bootstrap-select')
                                                .find('button.selectpicker'));
        }, 250)
      }, 500);
    }
  },
  updateRenewalRateInfo             : function() {
    var date      = '';
    var plan_code = plan_selector.$plan_selector.val();
    if (plan_code === '--') {
      plan_selector.$renewal_rate_container.addClass('o-fade');
      return true;
    }
    var price             = plan_selector.$plan_selector.find('option[value=' + plan_code + ']');
    var annual_or_monthly = plan_selector.$plan_selector.attr('data-current-display');
    if (annual_or_monthly === 'annual') {
      price = price.attr('data-annual');
    }
    else {
      plan_selector.$renewal_rate_container.addClass('o-fade');
      return true;
    }
    price.trim();
    plan_selector.$renewal_price.text(price);
    plan_selector.$renewal_rate_container.removeClass('o-fade');
  },
  switchAnnualMonthlyRateInSelection: function(annual_or_monthly) {
    var $options = plan_selector.$plan_selector.find('option:not([value=--])');
    if (annual_or_monthly === 'annual') {
      if (plan_selector.$plan_selector.attr('data-current-display') !== 'annual') {
        $options.each(function() {
            var $this = $(this);
            var text  = $this.attr('data-plan-title') + ' - 12 months @ $' + $this.attr('data-intro') + '/mo';
            $this.text(text);
          }
        );
      }
      plan_selector.$plan_selector.attr('data-current-display', 'annual');
    }
    else if (annual_or_monthly === 'monthly') {
      if (plan_selector.$plan_selector.attr('data-current-display') !== 'monthly') {
        $options.each(function() {
            var $this = $(this);
            var text  = $this.attr('data-plan-title') + ' - 1 month @ $' + $this.attr('data-monthly') + '/mo';
            $this.text(text);
          }
        );
      }
      plan_selector.$plan_selector.attr('data-current-display', 'monthly');
    }
    else {
      if (plan_selector.$plan_selector.attr('data-current-display') !== '') {
        $options.each(function() {
            var $this = $(this);
            var text  = $this.attr('data-plan-title');
            $this.text(text);
          }
        );
      }
      plan_selector.$plan_selector.attr('data-current-display', '');
    }
    // plan_selector.$plan_selector.selectpicker('refresh');
  },

  initiate: function() {
    $('#app-layout .selectpicker').selectpicker();
    plan_selector.$plan_selector = $("#plan-select-box");
    if (plan_selector.$plan_selector.length) {

      plan_selector.$selector_to_be_set = plan_selector.$plan_selector;
      //----------------------------------------------------------------------------------
      // initialize jquery selections
      //----------------------------------------------------------------------------------

      // noinspection JSJQueryEfficiency
      plan_selector.$plan_buttons           = $("#plan-flex-box .btn[data-plan-code]");
      plan_selector.$plan_pop_up            = $("#plan-pop-up-container");
      plan_selector.$renewal_rate_container = $(".plan-selector-renewal-price-container");
      plan_selector.$renewal_price          = $(".plan-selector-renewal-price");
      plan_selector.$annual_monthly         = $("input[type=radio][name=annual_monthly]");

      //----------------------------------------------------------------------------------
      // initialize event listeners
      //----------------------------------------------------------------------------------

      plan_selector.$plan_selector.on('change blur input keydown click', function() {
        plan_selector.planPopUpSetSelected();
        plan_selector.updateRenewalRateInfo();
      });

      plan_selector.$plan_buttons.click(function(e) {
        if (e.stopPropagation) {
          e.stopPropagation();
        }

        plan_selector.planPopUpSelected($(e.target).closest('.btn[data-plan-code]'));
        plan_selector.updateRenewalRateInfo();
      });

      $('#plan-pop-up-button').click(function(e) {
        plan_selector.$selector_to_be_set = $(e.target).closest('.plan-selector-container').find('.plan-selector');
        plan_selector.planPopUpOpen(e);
      });

      $('#plan-pop-up-close, #feature-header-spacer').click(function(e) {
        if (e.stopPropagation) {
          e.stopPropagation()
        }
        ;
        plan_selector.planPopUpClose();
      });
      var $plan_pop_up = $('#plan-pop-up ').click(function(e) {
        if (e.stopPropagation) {
          e.stopPropagation()
        }
        ;
        plan_selector.planPopUpClose();
      });
      $plan_pop_up.find(' > .container').click(function(e) {
        if (e.stopPropagation) {
          e.stopPropagation()
        }
      });
      if (plan_selector.$annual_monthly.length === 2) {
        plan_selector.$annual_monthly.on('input change blur keyup', function() {
          plan_selector.switchAnnualMonthlyRateInSelection(plan_selector.$annual_monthly.filter(':checked').val());
          plan_selector.updateRenewalRateInfo();
        });
      }

      //----------------------------------------------------------------------------------
      // prepare initial state
      //----------------------------------------------------------------------------------
      var $preselected_plan = $('#preselected-plan-code');
      if ($preselected_plan.length > 0 && $preselected_plan.val() !== '--') {
        plan_selector.$plan_selector.val($preselected_plan.val());
        // plan_selector.$plan_selector.selectpicker('val', $preselected_plan.val());
      }
      plan_selector.planPopUpSetSelected();
      plan_selector.updateRenewalRateInfo();

      var $black_screen = plan_selector.$plan_pop_up.next('.black-screen');
      plan_selector.$plan_pop_up.appendTo($('#modals'));
      $black_screen.appendTo($('#modals'));

      //  End of if statement wrapping container
    }
  }
};
plan_selector.initiate();

