var orderFinalizePage = {
  $finalize_order_display: null,
  $process_bar           : null,
  $process_bar_container : null,
  $progress_bar          : null,
  $process_status_domain : null,
  $process_status_text   : null,
  status                 : null,

  checkOrderStatus: function() {
    $.ajax({
      url       : siteGlobals.url + '/check-status',
      type      : "POST",
      beforeSend: function(xhr) {
        return xhr.setRequestHeader('X-CSRF-TOKEN', siteGlobals.csrfToken)
      },
      success   : function(response) {
        if (response['orderStatus']['status'] === 'error') {
          setTimeout(function() {
            window.location.assign(response['error_page']);
          }, 0);
          return;
        }

        var domain               = response['orderStatus']['domain'];
        var status               = response['orderStatus']['statusText'];
        var percentCompleted     = response['orderStatus']['progressbar'];
        orderFinalizePage.status = response['orderStatus']['status'];

        orderFinalizePage.updateProcessBar(domain, status, percentCompleted);
        if (response['orderStatus']['status'] === 'complete') {
          if (response['autologin'] != '') {
            setTimeout(function() {
              window.location.assign(response['autologin']);
            }, 0);
          }
        }

      }
    });
  },

  updateProcessBar: function(domain, status, percentCompleted) {
    percentCompleted = Math.min(percentCompleted, 100);
    percentCompleted = Math.max(percentCompleted, 5);

    if (parseInt(orderFinalizePage.$progress_bar.attr('style').substr(7, 3)) != percentCompleted) {
      orderFinalizePage.$progress_bar.width("calc(" + percentCompleted + "% - 2px)");
    }
    domain = "Setting Up Service: " + domain;
    if (orderFinalizePage.$process_status_domain.text() != domain) {
      orderFinalizePage.$process_status_domain.text(domain);
    }
  },
  initiate        : function() {
    orderFinalizePage.$finalize_order_display = $("#finalize-order-display");
    if (orderFinalizePage.$finalize_order_display.length > 0) {
      orderFinalizePage.$process_bar           = $('#full-process-bar');
      orderFinalizePage.$progress_bar          = orderFinalizePage.$process_bar.find('.progress-bar');
      orderFinalizePage.$process_status_domain = orderFinalizePage.$process_bar.find('#process-status-domain');
      orderFinalizePage.$process_status_text   = orderFinalizePage.$process_bar.find('#process-status-text');
      orderFinalizePage.$process_bar_container = $('#process-bar-spacer');

      setInterval(orderFinalizePage.checkOrderStatus, 2000);
    }
  }
};

orderFinalizePage.initiate();

