var siteNavbar = {


  $navbar                  : null, // jQuery access to the #navbar element
  $scroll_to_top_button    : null, // jQuery access to the #scroll-to-top-button element
  $mobile_nav_button       : null, // jQuery access to the [data-target=#navbar-collapse] element
  $navbar_collapse         : null, // jQuery access to the #navbar-collapse element
  $window                  : null, // jQuery access to the global window object
  scroll_timeout           : null, // any pending scroll_timeout
  resize_timeout           : null, // any pending resize_timeout
  mobile_nav_button_timeout: null, // any pending mobile_nav_button_Timeout
  window_height            : null, // pixel measurement of window height (AKA 100vh)
  calc_height_for_cp_nav   : null, // pixel measurement that the nav min height when domain chooser is open

  /**
   * @call        => siteNavbar.compact();
   *
   * @date        => May 5, 2016
   *
   * @description => Adds the class 'compact' to the #navbar element which:
   *                    - removes the 20px padding from the top and bottom.
   *                    - changes the height from 90px to 50px
   *
   */
  compact: function() {
    siteNavbar.$navbar.addClass('compact');
  },

  /**
   * @call        => siteNavbar.expand();
   *
   * @date        => May 5, 2016
   *
   * @description => Removes the class 'compact' from the #navbar element which:
   *                    - re-adds the 20px padding to the top and bottom.
   *                    - changes the height from 50px to 90px
   *
   */
  expand: function() {
    siteNavbar.$navbar.removeClass('compact');
  },

  /**
   * @call        => siteNavbar.scrolledEnoughToCompact();
   *
   * @date        => May 5, 2016
   *
   * @description => Tests current scroll position to see if the user has scrolled one full screen height down.
   *
   * @return      boolean
   *                    Returns true if the user has scrolled far enough for the compact function to be needed
   *                    Returns false if the user has not scrolled far enough for the compact function to be needed
   *
   */
  scrolledEnoughToCompact: function() {
    return (siteNavbar.window_height < siteNavbar.$window.scrollTop());
  },

  /**
   * @call        => siteNavbar.mobileNavIsOpen();
   *
   * @description => Tests current scroll position to see if the user has scrolled one full screen height down.
   *
   * @return      boolean
   *                    Returns false if the mobile nav menu is open
   *                    Otherwise returns true
   *
   */
  mobileNavIsOpen: function() {
    return siteNavbar.$navbar_collapse.attr('aria-expanded') == "true";
  },

  /**
   * @call        => siteNavbar.scrollHandler();
   *
   * @date        => May 6, 2016
   *
   * @description => If the scroll position permits the navbar to be compact it is done so if not it is made to be
   *                 expanded.
   *
   *
   */
  scrollHandler: function() {
    if (siteNavbar.scrolledEnoughToCompact() || siteNavbar.mobileNavIsOpen()) {
      siteNavbar.compact();
    }
    else {
      siteNavbar.expand();
    }
    siteNavbar.scroll_timeout = null
  },

  /**
   * @call        => siteNavbar.resizeHandler();
   *
   * @description => update the siteNavbar.window_height with the current value
   *
   *
   */
  resizeHandler: function() {
    siteNavbar.window_height = siteNavbar.$window.height();
    if (siteNavbar.$cp_nav_bar.length > 0 && !('ontouchstart' in window)) {
      if (window.innerWidth < 992) {
        siteNavbar.$cp_nav_bar.find('.dropdown-toggle:not(.sm-window-size)')
          // .attr('href', 'javascript:void(0)')
          // .unbind('click',siteGlobals.bootstrapOnlyHoverActivateHandler)
                  .addClass('sm-window-size');
      }

      else {
        siteNavbar.$cp_nav_bar.find('.dropdown-toggle.sm-window-size')
                  .removeClass('sm-window-size');
        // .click(siteGlobals.bootstrapOnlyHoverActivateHandler);
        // .each(function()
        // {
        //   $(this).attr('href', $(this).attr('data-href'));
        // });
      }
    }
  },
  ignoreClick  : function(e) {
    if (e.stopPropagation) {
      e.stopPropagation()
    }
  },

  /**
   * @call        => siteNavbar.checkAndSetNavLinkStatus();
   *
   * @date        => August 30, 2016
   *
   * @description => Checks if elements on page exist and links to them instead of new pages if at all possible.
   *
   */
  checkAndSetNavLinkStatus: function() {
    // var $triple_feature_highlight = siteGlobals.$site_page_content.find(".features.stripe");
    // var $highlighted_features = siteGlobals.$site_page_content.find("#highlighted-features");
    // var $pricing_table = siteGlobals.$site_page_content.find("#pricing-and-features-table");
    var $order_form   = siteGlobals.$site_page_content.find("#order-stripe");
    var $order_form_b = siteGlobals.$site_page_content.find("#order-form");

    if ($order_form.length > 0) {
      $('#free-trial-nav-link').click(function(e) {
        if (e.preventDefault) {
          e.preventDefault();
        }
        siteGlobals.scrollToElement($order_form_b);
      });
      $('[href="#order-stripe"]').click(function(e) {
        if (e.preventDefault) {
          e.preventDefault();
        }
        siteGlobals.scrollToElement($order_form);
        focusElementPreventScroll($("#full_name"));
      });
      $('[href="#order-form"]').click(function(e) {
        if (e.preventDefault) {
          e.preventDefault();
        }
        siteGlobals.scrollToElement($order_form_b);
        focusElementPreventScroll($("#full_name"));
      });
    }
  },


  /**
   * @call        => siteNavbar.initiateNavbarSettingsAndEvents();
   *
   * @date        => May 5, 2016
   *    @edit        => May 30, 2016
   *                    De-bounce and small optimizations
   *
   * @description => Initializes starting values, saves jQuery access to local globals, and sets event handlers.
   *
   */
  initiateNavbarSettingsAndEvents: function() {


    siteNavbar.$navbar               = $("#navbar");
    siteNavbar.$scroll_to_top_button = $("#scroll-to-top-button");
    siteNavbar.$domainChooser        = $("#domainChooserSelect");
    siteNavbar.$accountChooser       = $("#accountChooserSelect");
    siteNavbar.$mobile_nav_button    = siteNavbar.$navbar.find("[data-target='#navbar-collapse']");
    siteNavbar.$navbar_collapse      = $("#navbar-collapse");
    siteNavbar.$window               = $(window);
    siteNavbar.$cp_nav_bar           = $("#cp-nav-bar");


    if (siteNavbar.$cp_nav_bar.length > 0) {
      siteNavbar.$cp_nav_bar.find('.dropdown-toggle').each(function() {
        $(this).attr('data-href', $(this).attr('href'));
      });
    }
    siteNavbar.resizeHandler();
    if (siteNavbar.$domainChooser.length > 0) {
      setTimeout(function() {
        siteNavbar.$domainChooser.selectpicker({
          dropupAuto: false
        });
        siteNavbar.$domainChooser.selectpicker('setStyle', 'hugRight', 'add');
        var $selector = siteNavbar.$domainChooser.siblings('.bootstrap-select');


        var $menu = $selector.find('.dropdown-menu');
        setTimeout(function() {
          siteNavbar.$navbar_collapse.css('display', 'block');
          $menu.css('display', 'block');
          siteNavbar.calc_height_for_cp_nav =
            $menu.offset().top - siteNavbar.$navbar_collapse.offset().top + $menu.outerHeight() - 38;
          siteNavbar.$navbar_collapse.css('display', '');
          $menu.css('display', '');
        }, 0);

        $selector.on('mouseup', function(e) {
            if (window.innerWidth < 992) {
              var $menu   = $(e.target).closest('.bootstrap-select').find('.dropdown-menu');
              var $navbar = $('#navbar-collapse.cp');

              if (!$menu.is(':visible')) {
                $navbar.css('min-height', siteNavbar.calc_height_for_cp_nav);
                // siteGlobals.$window.resize(function(){});
              }
              else {
                $navbar.css('min-height', '');
              }
            }
          }
        );
      }, 0);
    }

    // Borrowed code from stack overflow here to de-bounce the scroll event
    // http://stackoverflow.com/a/15591162/4059832
    // de-bounced scroll handler
    // using a short timeout to wait until user has stopped scrolling to run any events
    if (siteGlobals.url.replace(siteGlobals.base_url, '') === '' ||
        siteGlobals.url.replace(siteGlobals.base_url, '').indexOf("#") === 0 ||
        siteGlobals.url.replace(siteGlobals.base_url, '') === 'reseller-program' ||
        siteGlobals.url.replace(siteGlobals.base_url, '') === 'beta/reseller-program' ||
        siteGlobals.url.replace(siteGlobals.base_url, '') === 'beta/') {
      if (spicy) {
        siteNavbar.$window.scroll(
          function() {
            if (siteNavbar.scroll_timeout !== null) {
              // clear the timeout, if one is pending
              clearTimeout(parseInt(siteNavbar.scroll_timeout));
              siteNavbar.scroll_timeout = null;
            }
            siteNavbar.scroll_timeout =
              setTimeout(
                function() {
                  siteNavbar.scrollHandler();
                },
                50 // super short timeout
                // If this is longer the animation takes too long to play
                // This short time is still helping clean up the dozens of useless scroll events firing
              );
          }
        );
      }
    }
    // de-bounced resize handler
    // using a short timeout to wait until user has stopped altering screen size to run any events

    siteNavbar.$window.resize(
      function() {
        if (siteNavbar.resize_timeout != null) {
          // clear the timeout, if one is pending
          clearTimeout(siteNavbar.resize_timeout);
          siteNavbar.resize_timeout = null;
        }
        siteNavbar.resize_timeout =
          setTimeout(
            function() {
              siteNavbar.resizeHandler();
            },
            250
          );
      }
    );

    if (siteNavbar.$domainChooser.length > 0) {
      siteNavbar.$domainChooser.on('input change blur keyup',
        function() {
          $(this).closest("form").submit();
        }
      );
    }
    if (siteNavbar.$accountChooser.length > 0) {
      siteNavbar.$accountChooser.on('input change blur keyup',
        function() {
          $(this).closest("form").submit();
        }
      );
    }

    // click handler for #scroll-to-top-button

    siteNavbar.$scroll_to_top_button.click(
      function() {
        siteGlobals.scrollToX(0);
      }
    );

    siteNavbar.$mobile_nav_button.click(
      function() {
        if (siteNavbar.mobile_nav_button_timeout !== null) {
          // clear the timeout, if one is pending
          clearTimeout(parseInt(siteNavbar.mobile_nav_button_timeout));
          siteNavbar.mobile_nav_button_timeout = null;
        }
        siteNavbar.mobile_nav_button_timeout =
          setTimeout(
            function() {
              siteNavbar.scrollHandler();
            },
            50
          );
      }
    );


    siteNavbar.checkAndSetNavLinkStatus();

  }
};
siteNavbar.initiateNavbarSettingsAndEvents();

