var existing_order_details = {
  $ui_radios : null,
  $ui_radio_1: null,
  $ui_radio_2: null,

  $billing_methods : null,
  $billing_method_1: null,
  $billing_method_2: null,
  $billing_method_3: null,

  $billing_panel_toggle_1: null,
  $billing_panel_toggle_2: null,

  $billing_panel_heading_1: null,
  $billing_panel_heading_2: null,

  $account: null,

  $billing_address_form: null,

  $cc_inputs             : null,
  $ach_inputs            : null,
  $billing_address_inputs: null,

  click_loop_breaker: false,

  click_loop_breaker_timeout: null,

  click_loop_breaker_activate: function() {
    existing_order_details.click_loop_breaker = true;

    if (existing_order_details.click_loop_breaker_timeout != null) {
      // clear the timeout, if one is pending
      clearTimeout(parseInt(existing_order_details.click_loop_breaker_timeout));
      existing_order_details.click_loop_breaker_timeout = null;
    }

    //start a time out to let them continue without filling out the form or waiting the minimum time
    existing_order_details.click_loop_breaker_timeout = setTimeout(function() {
      existing_order_details.click_loop_breaker = false;
    }, 20);
  },


  initiate: function() {
    if ($('#existing-customer-order-details').length) {

      //----------------------------------------------------------------------------------
      // initialize jquery selections
      //----------------------------------------------------------------------------------
      existing_order_details.$ui_radios  = $('#ui-radio-1, #ui-radio-2');
      existing_order_details.$ui_radio_1 = $('#ui-radio-1');
      existing_order_details.$ui_radio_2 = $('#ui-radio-2');


      existing_order_details.$billing_methods  = $('#billing-method-1, #billing-method-2, #billing-method-3');
      existing_order_details.$billing_method_1 = $('#billing-method-1');
      existing_order_details.$billing_method_2 = $('#billing-method-2');
      existing_order_details.$billing_method_3 = $('#billing-method-3');
      existing_order_details.$billing_method_4 = $('#billing-method-4');

      existing_order_details.$billing_panel_toggle_1 = $('#billing-method-panel-heading-1');
      existing_order_details.$billing_panel_toggle_2 = $('#billing-method-panel-heading-2');

      existing_order_details.$billing_panel_heading_1 =
        existing_order_details.$billing_panel_toggle_1.closest('.card-header');
      existing_order_details.$billing_panel_heading_2 =
        existing_order_details.$billing_panel_toggle_2.closest('.card-header');

      existing_order_details.$account = $('#account');

      existing_order_details.$billing_address_form = $('#billing-address-form');

      existing_order_details.$billing_address_inputs =
        existing_order_details.$billing_address_form.find('input, select');
      existing_order_details.$cc_inputs              = $('.cc');
      existing_order_details.$ach_inputs             = $('.ach');

      //----------------------------------------------------------------------------------
      // initialize event listeners
      //----------------------------------------------------------------------------------


      existing_order_details.$ui_radios.on('input change blur keyup', function() {
        if (existing_order_details.$ui_radio_1.is(':checked')) {
          existing_order_details.$billing_method_1.click();
        }
        else {
          if (!existing_order_details.$billing_method_2.is(':checked') &&
              !existing_order_details.$billing_method_3.is(':checked')) {
            existing_order_details.$billing_method_4.click();
          }
        }
      });

      //----------------------------------------------------------------------------------
      existing_order_details.$billing_panel_heading_1.click(function() {
        if (!existing_order_details.click_loop_breaker) {
          existing_order_details.click_loop_breaker_activate();
          existing_order_details.$billing_method_1.click();
          existing_order_details.$billing_panel_toggle_1.click();
        }
      });
      existing_order_details.$billing_panel_heading_2.click(function() {
        if (!existing_order_details.click_loop_breaker) {
          existing_order_details.click_loop_breaker_activate();
          if (!existing_order_details.$billing_method_2.is(':checked') &&
              !existing_order_details.$billing_method_3.is(':checked')) {
            existing_order_details.$billing_method_2.click();
            existing_order_details.$billing_panel_toggle_2.click();
          }
        }
      });
      //----------------------------------------------------------------------------------

      existing_order_details.$billing_methods.on('input change blur keyup', function() {
        if (existing_order_details.$billing_method_1.is(':checked')) {
          existing_order_details.$account.removeClass('disabled').siblings('.bootstrap-select').removeClass('disabled');
          existing_order_details.$billing_address_form.css('position', 'absolute');
          existing_order_details.$ui_radio_1.click();
          existing_order_details.$billing_address_inputs.attr('tabindex', '-1');
          existing_order_details.$cc_inputs.attr('tabindex', '-1');
          existing_order_details.$ach_inputs.attr('tabindex', '-1');
        }
        else {
          existing_order_details.$account.addClass('disabled').siblings('.bootstrap-select').addClass('disabled');
          existing_order_details.$billing_address_form.css('position', '');
          existing_order_details.$ui_radio_2.click();
          existing_order_details.$billing_address_inputs.attr('tabindex', '');
          if (existing_order_details.$billing_method_2.is(':checked')) {
            existing_order_details.$cc_inputs.attr('tabindex', '');
            existing_order_details.$ach_inputs.attr('tabindex', '-1');
          }
          if (existing_order_details.$billing_method_3.is(':checked')) {
            existing_order_details.$cc_inputs.attr('tabindex', '-1');
            existing_order_details.$ach_inputs.attr('tabindex', '');
          }
        }
      });
      //----------------------------------------------------------------------------------

      existing_order_details.$account.on('input change blur keyup', function() {
        existing_order_details.$ui_radio_1.click();
      });
      //----------------------------------------------------------------------------------
      if (existing_order_details.$billing_method_2.is(':checked') ||
          existing_order_details.$billing_method_3.is(':checked')) {
        existing_order_details.$ui_radio_2.click();
        existing_order_details.$billing_address_form.css('position', '');
        if (existing_order_details.$billing_method_2.is(':checked')) {
          existing_order_details.$cc_inputs.attr('tabindex', '');
          existing_order_details.$ach_inputs.attr('tabindex', '-1');
        }
        if (existing_order_details.$billing_method_3.is(':checked')) {
          existing_order_details.$cc_inputs.attr('tabindex', '-1');
          existing_order_details.$ach_inputs.attr('tabindex', '');
        }
      }

      //  End of if statement wrapping container
    }
  }
};
existing_order_details.initiate();

