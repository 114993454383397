var quote = {

  $quote_container               : null,
  $plan_select_box               : null,
  $billing_schedule              : null,
  $plan_price_display            : null,
  $plan_renewal_price_container  : null,
  $plan_renewal_price_display    : null,
  $quarantine_users              : null,
  $quarantine_users_price_display: null,
  $monthly_inbound_emails        : null,
  $inbound_emails_price_display  : null,
  $monthly_outbound_emails       : null,
  $outbound_emails_price_display : null,
  $domains                       : null,
  $domains_display               : null,
  $total_price_display           : null,
  $total_renewal_price_container : null,
  $total_renewal_price_display   : null,
  $annual                        : null,
  $monthly                       : null,
  $inputs                        : null,
  debounceTimeout                : null,
  feature_unavailable            : false,


  plan_details             : null,
  important_feature_details: null,


  debouncedQuoteRequest: function(time) {
    if (quote.debounceTimeout !== null) {
      clearTimeout(parseInt(quote.debounceTimeout));
    }
    quote.debounceTimeout = setTimeout(function() {
      quote.debounceTimeout = null;
      quote.updateFields(false);
    }, time)
  },

  updateFields: function(default_to_included) {
    var plan_code        = quote.$plan_select_box.val() === '--' ? 'sd' : quote.$plan_select_box.val();
    var billing_schedule = quote.$billing_schedule.val();
    if (billing_schedule === 'annual') {
      quote.$annual.removeClass('hidden');
      quote.$monthly.addClass('hidden');
      plan_selector.switchAnnualMonthlyRateInSelection('annual');
      plan_selector.updateRenewalRateInfo();
    }
    else {
      quote.$annual.addClass('hidden');
      quote.$monthly.removeClass('hidden');
      plan_selector.switchAnnualMonthlyRateInSelection('monthly');
      plan_selector.updateRenewalRateInfo();
    }
    if (typeof quote.plan_details[plan_code]['monthly_price'] === 'string' &&
        quote.plan_details[plan_code]['monthly_price'].length > 0) {
      quote.$billing_schedule.prop('disabled', false).find('[value=monthly]').prop('disabled', false).text('Monthly');
      quote.$billing_schedule.closest('.row').find('.quote-label-cell').removeClass('disabled');
    }
    else {
      quote.$billing_schedule.prop('disabled', true)
           .find('[value=monthly]')
           .prop('disabled', true)
           .text('Monthly (not available for this plan)');
      quote.$billing_schedule.closest('.row').find('.quote-label-cell').addClass('disabled');
    }
    quote.updatePlanPrice(plan_code, billing_schedule, default_to_included);
    quote.updateQuarantineUsers(plan_code, billing_schedule);
    quote.updateInboundEmails(plan_code, billing_schedule, default_to_included);
    quote.updateOutboundEmails(plan_code, billing_schedule, default_to_included);

    if (parseInt(quote.$domains.val()) > 99999) {
      quote.$domains.val(99999);
    }
    if (parseInt(quote.$domains.val()) < 2) {
      quote.$domains.val(1);
    }
    var domainsDisp = quote.$domains.val();
    if (isNaN(parseInt(quote.$domains.val()))) {
      domainsDisp = 1;
    }
    quote.$domains_display.text(domainsDisp).toLocaleString();
    quote.calculateTotal();
    if (quote.feature_unavailable === true) {
      toast({
        message: 'Some selected features have been reset as they are not available with this plan.',
        type   : 'warning',
        time   : 6500,
        icon   : 'info'
      });

    }
    quote.feature_unavailable = false;


    return true;
  },


  updatePlanPrice      : function(plan_code, billing_schedule, default_to_included) {
    var plan_price = quote.plan_details[plan_code];
    if (billing_schedule === 'annual') {
      if (plan_price['annual_price'] === plan_price['introductory_price']) {
        plan_price = plan_price['annual_price'];
        quote.$plan_renewal_price_container.addClass('hidden');
      }
      else {
        quote.$plan_renewal_price_container.removeClass('hidden');
        plan_price = plan_price['annual_price'];
        quote.$plan_renewal_price_display.text(plan_price.toLocaleString());
        plan_price = quote.plan_details[plan_code]['introductory_price'];
      }

    }
    else {
      quote.$plan_renewal_price_container.addClass('hidden');
      plan_price = plan_price['monthly_price'];
      if (typeof plan_price === 'undefined' || String(plan_price).length < 1) {
        quote.$billing_schedule.val('annual');
        quote.feature_unavailable = true;
        return quote.updateFields(default_to_included);
      }
    }

    quote.$plan_price_display.text(parseInt(plan_price).toLocaleString());
  },
  updateQuarantineUsers: function(plan_code, billing_schedule) {
    if (parseInt(quote.$quarantine_users.val()) > 9999999) {
      quote.$quarantine_users.val(9999999);
    }
    if (parseInt(quote.$quarantine_users.val()) < 1) {
      quote.$quarantine_users.val(0);
    }
    if (quote.important_feature_details['quarantine_users'][plan_code]['included']) {
      var quarantine_users_price = quote.$quarantine_users.val();
      quarantine_users_price *= quote.important_feature_details['quarantine_users'][plan_code]['price'];
      if (billing_schedule === 'annual') {
        quarantine_users_price *= 12; // 12 months in a year
      }
      quote.$quarantine_users_price_display.text(quarantine_users_price.toLocaleString());
      quote.$quarantine_users_price_display.closest('.row').removeClass('disabled');
    }
    else {
      quote.$quarantine_users_price_display.closest('.row').addClass('disabled');
      if (parseInt(quote.$quarantine_users.val()) !== 0) {
        quote.feature_unavailable = true;
      }
      quote.$quarantine_users.val(0);
      quote.$quarantine_users_price_display.text(0);
    }
  },
  updateInboundEmails  : function(plan_code, billing_schedule, default_to_included) {

    var inbound_included = quote.important_feature_details['inbound_emails_included'][plan_code]['alt'];
    if (default_to_included) {
      quote.$monthly_inbound_emails.val(inbound_included);
    }
    if (parseInt(quote.$monthly_inbound_emails.val()) > 99999999) {
      quote.$monthly_inbound_emails.val(99999999);
    }
    if (parseInt(quote.$monthly_inbound_emails.val()) < 1) {
      quote.$monthly_inbound_emails.val(0);
    }
    var inbound_quarantine_additional = quote.important_feature_details['overageInbound']['quarantine']['quantity'] *
                                        quote.$quarantine_users.val();
    if (plan_code === 'et') {
      inbound_quarantine_additional = 0;
    }
    var inbound_included_total = parseInt(inbound_included) + parseInt(inbound_quarantine_additional);
    var inbound_overage        = Math.max((quote.$monthly_inbound_emails.val() - inbound_included_total), 0);
    var inbound_price          = Math.ceil(inbound_overage /
                                           quote.important_feature_details['overageInbound'][plan_code]['quantity']);
    inbound_price *= quote.important_feature_details['overageInbound'][plan_code]['price'];
    if (billing_schedule === 'annual') {
      inbound_price *= 12; // 12 months in a year
    }
    quote.$inbound_emails_price_display.text(inbound_price.toLocaleString());
  },

  updateOutboundEmails: function(plan_code, billing_schedule, default_to_included) {

    if (quote.important_feature_details['outbound_service'][plan_code]['included']) {
      var outbound_included = quote.important_feature_details['outbound_emails_included'][plan_code]['alt'];
      // if (parseInt(quote.$monthly_outbound_emails.val()) < parseInt(outbound_included) || default_to_included) {
      //    quote.$monthly_outbound_emails.val(outbound_included);
      // }
      if (default_to_included) {
        quote.$monthly_outbound_emails.val(outbound_included);
      }
      if (parseInt(quote.$monthly_outbound_emails.val()) < 1) {
        quote.$monthly_outbound_emails.val(0);
      }
      if (parseInt(quote.$monthly_outbound_emails.val()) > 99999999) {
        quote.$monthly_outbound_emails.val(99999999);
      }
      var outbound_quarantine_additional = quote.important_feature_details['overageOutbound']['quarantine']['quantity'] *
                                           quote.$quarantine_users.val();
      if (plan_code === 'et') {
        outbound_quarantine_additional = 0;
      }
      var outbound_included_total = parseInt(outbound_included) + parseInt(outbound_quarantine_additional);
      var outbound_overage        = Math.max((quote.$monthly_outbound_emails.val() - outbound_included_total), 0);
      var outbound_price          = Math.ceil(outbound_overage /
                                              quote.important_feature_details['overageOutbound'][plan_code]['quantity']);
      outbound_price *= quote.important_feature_details['overageOutbound'][plan_code]['price'];
      if (billing_schedule === 'annual') {
        outbound_price *= 12; // 12 months in a year
      }
      quote.$monthly_outbound_emails.closest('.row').removeClass('disabled');
      quote.$outbound_emails_price_display.text(outbound_price.toLocaleString());

    }
    else {
      quote.$monthly_outbound_emails.closest('.row').addClass('disabled');
      if (parseInt(quote.$monthly_outbound_emails.val()) !== 0) {
        quote.feature_unavailable = true;
      }
      quote.$monthly_outbound_emails.val(0);
      quote.$outbound_emails_price_display.text(0);
    }
  },


  calculateTotal: function() {
    quote.$total_price_display.text(((parseInt(quote.$plan_price_display.text().replace(/\D/g, ""))
                                      + parseInt(quote.$quarantine_users_price_display.text().replace(/\D/g, ""))
                                      + parseInt(quote.$inbound_emails_price_display.text().replace(/\D/g, ""))
                                      + parseInt(quote.$outbound_emails_price_display.text().replace(/\D/g, "")))
                                     * parseInt(quote.$domains_display.text().replace(/\D/g, ""))).toLocaleString());
    if (quote.$plan_renewal_price_container.hasClass('hidden')) {
      quote.$total_renewal_price_container.addClass('hidden');
    }
    else {
      quote.$total_renewal_price_display.text(((parseInt(quote.$plan_renewal_price_display.text().replace(/\D/g, ""))
                                                + parseInt(quote.$quarantine_users_price_display.text().replace(/\D/g, ""))
                                                + parseInt(quote.$inbound_emails_price_display.text().replace(/\D/g, ""))
                                                + parseInt(quote.$outbound_emails_price_display.text().replace(/\D/g, "")))
                                               * parseInt(quote.$domains_display.text().replace(/\D/g, ""))).toLocaleString());
      quote.$total_renewal_price_container.removeClass('hidden');
    }
  },

  initialize: function() {
    quote.$quote_container = $('#quote-container');
    if (quote.$quote_container.length >= 1) {

      quote.plan_details              = blade_layout['quote']['plan_details'];
      quote.important_feature_details = blade_layout['quote']['important_feature_details'];

      quote.$plan_select_box                = quote.$quote_container.find('#plan-select-box');
      quote.$billing_schedule               = quote.$quote_container.find('#billing-schedule');
      quote.$plan_price_display             = quote.$quote_container.find('#plan-price-display');
      quote.$plan_renewal_price_container   = quote.$quote_container.find('#plan-renewal-price-container');
      quote.$plan_renewal_price_display     = quote.$quote_container.find('#plan-renewal-price-display');
      quote.$quarantine_users               = quote.$quote_container.find('#quarantine-users');
      quote.$quarantine_users_price_display = quote.$quote_container.find('#quarantine-users-price-display');
      quote.$monthly_inbound_emails         = quote.$quote_container.find('#monthly-inbound-emails');
      quote.$inbound_emails_price_display   = quote.$quote_container.find('#inbound-emails-price-display');
      quote.$monthly_outbound_emails        = quote.$quote_container.find('#monthly-outbound-emails');
      quote.$outbound_emails_price_display  = quote.$quote_container.find('#outbound-emails-price-display');
      quote.$domains                        = quote.$quote_container.find('#domains');
      quote.$domains_display                = quote.$quote_container.find('#domains-display');
      quote.$total_price_display            = quote.$quote_container.find('#total-price-display');
      quote.$total_renewal_price_container  = quote.$quote_container.find('#total-renewal-price-container');
      quote.$total_renewal_price_display    = quote.$quote_container.find('#total-renewal-price-display');
      quote.$annual                         = quote.$quote_container.find('.annual');
      quote.$monthly                        = quote.$quote_container.find('.monthly');

      quote.$inputs = quote.$quote_container.find('input,select');

      quote.$inputs.on('change blur', function() {
        quote.debouncedQuoteRequest(50);
      });
      quote.$inputs.on('keydown keyup', function() {
        quote.debouncedQuoteRequest(300);
      });

      quote.$quote_container.find('#calculate-button').click(function() {
        quote.updateFields(false)
      });

      quote.$plan_select_box.on('input change blur keyup', function() {
        quote.updateFields(true);
      });
      quote.updateFields(true);
    }
  }

};


quote.initialize();

