var reseller_order_details = {

      $subaccount_add_new_radios: null,
      $subaccount_add_new_1     : null,
      $subaccount_add_new_2     : null,

      $subAccount: null,

      $subaccount_toggle_open : null,
      $subaccount_toggle_close: null,

      $subaccount_add_new_check: null,

      $subuser_add_new_form: null,
      // $subuser_add_new_form_toggle: null,
      $subuser_add_new     : null,
      $subuser_fieldset    : null,

      click_loop_breaker: false,

      click_loop_breaker_timeout: null,

      click_loop_breaker_activate: function() {
        reseller_order_details.click_loop_breaker = true;

        if (reseller_order_details.click_loop_breaker_timeout != null) {
          // clear the timeout, if one is pending
          clearTimeout(parseInt(reseller_order_details.click_loop_breaker_timeout));
          reseller_order_details.click_loop_breaker_timeout = null;
        }

        //start a time out to let them continue without filling out the form or waiting the minimum time
        reseller_order_details.click_loop_breaker_timeout = setTimeout(function() {
          reseller_order_details.click_loop_breaker = false;
        }, 20);
      },

      initiate: function() {
        if ($('#reseller-order-details').length) {

          //----------------------------------------------------------------------------------
          // initialize jquery selections
          //----------------------------------------------------------------------------------


          reseller_order_details.$subaccount_add_new_radios = $('#subaccount-add-new-1, #subaccount-add-new-2');
          reseller_order_details.$subaccount_add_new_1      = $('#subaccount-add-new-1');
          reseller_order_details.$subaccount_add_new_2      = $('#subaccount-add-new-2');


          reseller_order_details.$subaccount_add_new_check = $('#subaccount-add-new-check');

          reseller_order_details.$subaccount_toggle_open  = $('#subaccount-toggle-open');
          reseller_order_details.$subaccount_toggle_close = $('#subaccount-toggle-close');

          reseller_order_details.$subuser_add_new_form        = $('#subuser-add-new-form');
          reseller_order_details.$subuser_add_new_form_toggle = $('#subuser-add-new-form-toggle');
          reseller_order_details.$subuser_add_new             = $('#subuser-add-new');
          reseller_order_details.$subuser_fieldset            = $('#subuser-fieldset');

          reseller_order_details.$subAccount = $('#subaccount');


          //----------------------------------------------------------------------------------
          // initialize event listeners
          //----------------------------------------------------------------------------------

          //----------------------------------------------------------------------------------
          reseller_order_details.$subaccount_toggle_open.click(function() {
            if (!reseller_order_details.click_loop_breaker) {
              reseller_order_details.click_loop_breaker_activate();
              reseller_order_details.$subaccount_add_new_1.click();
            }
          });
          reseller_order_details.$subaccount_toggle_close.click(function() {
            if (!reseller_order_details.click_loop_breaker) {
              reseller_order_details.click_loop_breaker_activate();
              reseller_order_details.$subaccount_add_new_2.click();
            }
          });

          // reseller_order_details.$subuser_add_new_form_toggle.click(function()
          // {
          //   if (!reseller_order_details.click_loop_breaker) {
          //     reseller_order_details.click_loop_breaker_activate();
          //     reseller_order_details.$subaccount_add_new_2.click();
          //   }
          // });

          // //----------------------------------------------------------------------------------


          reseller_order_details.$subuser_add_new
                                .on('input change blur keyup', function() {
                                  if (reseller_order_details.$subuser_add_new.is(':checked')) {
                                    reseller_order_details.$subuser_fieldset
                                                          .removeClass('closed');
                                    reseller_order_details.$subuser_add_new_form.collapse('show');

                                  }
                                  else {
                                    reseller_order_details.$subuser_fieldset
                                                          .addClass('closed');
                                    reseller_order_details.$subuser_add_new_form.collapse('hide');
                                  }
                                });

          reseller_order_details.$subAccount.click(function(e) {
            reseller_order_details.$subaccount_add_new_2.click();
          });
          reseller_order_details.$subAccount.on('input change blur keyup', function() {
            reseller_order_details.$subaccount_add_new_2.click();
          });


          reseller_order_details.$subaccount_add_new_radios.on('change input click', function() {
            if (reseller_order_details.$subaccount_add_new_1.is(':checked')) {
              reseller_order_details.$subaccount_add_new_check.collapse('show');
            }
            else {
              reseller_order_details.$subaccount_add_new_check.collapse('hide');
            }
            if (reseller_order_details.$subaccount_add_new_2.is(':checked')) {
              reseller_order_details.$subAccount.removeClass('disabled').data("selectpicker").$newElement.removeClass('disabled');
            }
            else {
              reseller_order_details.$subAccount.addClass('disabled').data("selectpicker").$newElement.addClass('disabled');
            }
          });

        }
      }
    }
;
reseller_order_details.initiate();

