

var slickStart =
      {

        $prev_arrow: $('<button></button>')
          .attr('type', 'button')
          .addClass('slick-prev')
          .append($('<div></div>')
            .addClass('slider-prev fa fa-chevron-circle-left fa-2x fa-fw')),
        // local global to store jQuery access to a freshly created .slick-prev element

        $next_arrow: $('<button></button>')
          .attr('type', 'button')
          .addClass('slick-next')
          .append($('<div></div>')
            .addClass('slider-next fa fa-chevron-circle-right fa-2x fa-fw')),
        // local global to store jQuery access to a freshly created .slick-next element


        slickTestimonialsBasicHorizontal: function() {
          var $testimonials = $("#testimonials-basic-horizontal-slider");
          if ($testimonials.length > 0) {
            $testimonials.slick({
              prevArrow    : slickStart.$prev_arrow.html(),
              nextArrow    : slickStart.$next_arrow.html(),
              arrows       : false,
              accessibility: false,
              draggable    : false,
              pauseOnHover : true,
              pauseOnFocus : false,
              touchMove    : false,
              slidesToShow : 1,
              autoplay     : true,
              autoplaySpeed: 9000
            });
          }
        },
        slickOrder                      : function() {
          var $order = $("#order-slider");
          if ($order.length > 0) {
            $order.slick({
              prevArrow    : slickStart.$prev_arrow.html(),
              nextArrow    : slickStart.$next_arrow.html(),
              arrows       : false,
              accessibility: false,
              infinite     : false,
              draggable    : false,
              swipe        : false,
              touchMove    : false,
              speed        : 300,
              slidesToShow : 1
            });
          }
        },

        slickTour: function() {
          var $tour = $("#tour");
          if ($tour.length > 0) {
            $tour.slick({
              prevArrow   : slickStart.$prev_arrow.html(),
              nextArrow   : slickStart.$next_arrow.html(),
              infinite    : false,
              dots        : true,
              slidesToShow: 1,
              responsive  : [
                {
                  breakpoint: 992,
                  settings  : {
                    arrows: false
                  }
                }
              ]
            });
          }
        },


        slickTestimonials: function() {
          var $testimonials = $("#testimonials");
          if ($testimonials.length > 0) {
            $testimonials.slick({
              prevArrow    : slickStart.$prev_arrow.html(),
              nextArrow    : slickStart.$next_arrow.html(),
              arrows       : false,
              accessibility: false,
              draggable    : false,
              pauseOnHover : false,
              pauseOnFocus : false,
              touchMove    : false,
              slidesToShow : 1,
              autoplay     : true,
              autoplaySpeed: 9000
            });
          }
        },

        initialize: function() {
          slickStart.slickOrder();
          slickStart.slickTestimonials();
          slickStart.slickTour();
          slickStart.slickTestimonialsBasicHorizontal();
          siteGlobals.reInitiate();
        }
      };
slickStart.initialize();


