$('.smart-date-picker').datetimepicker({
  useCurrent: false,
  icons     : {
    time    : "fa fa-clock",
    date    : "fa fa-calendar",
    up      : "fa fa-arrow-up",
    down    : "fa fa-arrow-down",
    next    : "fa fa-angle-right",
    previous: "fa fa-angle-left"
  }
});
$('.smart-date-picker-min-tomorrow').datetimepicker({
  minDate: new Date(new Date().getTime() + 60 * 60 * 24 * 1000), useCurrent: false,
  icons  : {
    time    : "fa fa-clock",
    date    : "fa fa-calendar",
    up      : "fa fa-arrow-up",
    down    : "fa fa-arrow-down",
    next    : "fa fa-angle-right",
    previous: "fa fa-angle-left"
  }
});
var dateNow = new Date();
$('.smart-time-picker').datetimepicker({
  useCurrent: false,
  icons     : {
    time    : "fa fa-clock",
    date    : "fa fa-calendar",
    up      : "fa fa-arrow-up",
    down    : "fa fa-arrow-down",
    next    : "fa fa-angle-right",
    previous: "fa fa-angle-left"
  },
  format    : 'LT',
  stepping  : 30,

  defaultDate: moment(dateNow).hours(12).minutes(0).seconds(0).milliseconds(0)
});

var $appLayout = $('#app-layout');

$appLayout.on('click', '[data-toggle=custom-collapse-open]', function(e) {
  var $toggle = $(e.target).closest('[data-toggle=custom-collapse-open]');
  if ($toggle.length) {
    var attr = $toggle.data('parent');


    if (typeof attr !== typeof undefined && attr !== false && attr !== '') {
      var $parent   = $(attr);
      var $siblings = $parent.find(':not([data-target="' +
                                   $toggle.data('target') +
                                   '"])[data-parent="' +
                                   attr +
                                   '"][data-toggle="custom-collapse-open"]');
      if ($siblings.length) {
        var $collapse_targets = $siblings.map(function() {
          return $(this).data('target');
        });
        $collapse_targets     = $($collapse_targets.toArray().join());
        $collapse_targets.collapse('hide');
      }
      $siblings.removeClass('custom-active-accordion');
    }
    $($toggle.data('target')).collapse('show');
    $toggle.addClass('custom-active-accordion');
  }
});
$appLayout.on('click', '[data-toggle=custom-collapse-close]', function(e) {
  var $toggle = $(e.target).closest('[data-toggle=custom-collapse-close]');
  if ($toggle.length) {
    $($toggle.data('target')).collapse('hide');
  }
});
if (!('ontouchstart' in window)) {
  // On desktop we use hover to activate drop-downs on mobile we use click/tap
  // Even on desktop, allow click to expand on narrow displays.
  $("body").on("click", 'ul.navbar-nav .dropdown-toggle:not(.sm-window-size)', function(e) {
    if (e.stopPropagation) {
      e.stopPropagation()
    }
  });
}

// $.fn.dropdown.Constructor.Default.boundary = "none";
// $.fn.dropdown.Constructor.Default.flip     = false;

var $dropHover = $('.drop-hover');


$dropHover.mouseenter(function() {
  var lastTimeout = $(this).data("timeout") || null;
  if (lastTimeout !== null) {
    clearTimeout(lastTimeout);
  }
  if (!$(".dropdown-menu", this).hasClass("show")) {
    $('.dropdown-toggle', this).dropdown('toggle');
  }
});

$dropHover.mouseleave(function() {
  var lastTimeout = $(this).data("timeout") || null;
  if (lastTimeout !== null) {
    clearTimeout(lastTimeout);
  }

  var that        = this;
  var thisTimeout = setTimeout(function() {
    if ($(".dropdown-menu", that).hasClass("show")) {
      $('.dropdown-toggle', that).dropdown('toggle');
    }
  }, 250);
  $(this).data("timeout", thisTimeout);

});


$dropHover.on("show.bs.dropdown", function() {
  var $toggle = $(this).find(".dropdown-toggle");
  if ($(this).data("fixed_bindings") !== "yes") {
    $(this).data("fixed_bindings", "yes");
    $(this).find("a").off("click");
    $toggle.off("click");
    $toggle.click(function(e) {
      e.stopPropagation();
    });
  }
});


$(document).ready(function() {
  $dropHover.find('.dropdown-toggle').on('touchstart', function(e) {
    $(this).dropdown("toggle");
    e.preventDefault();
    //Do touch stuff
  });
  $('[data-toggle="tooltip"]').tooltip({boundary: 'window'});
});

