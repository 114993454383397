var orderForm = {
  $order_form           : null,  // local global to store jQuery access to the #order-slider element
  $order_real_form      : null,  // This is the acutal form. The above element is just badly named.
  $order_steps          : null,  // local global to store jQuery access to the '.order-step' elements
  $process_bar          : null,  // local global to store jQuery access to the '#full-process-bar' element
  $process_bar_container: null,  // local global to store jQuery access to the '#process-bar-spacer' element
  $current_slide        : null,  // local global to store jQuery access to the current slide element as defined by
                                 // slick.js
  $current_inputs: null,  // local global to store jQuery access to the inputs on the $current_slide
  $all_inputs    : null,  // local global to store jQuery access to all input fields from the #order-slider
                          // element
  $progress_bar         : null,  // local global to store jQuery access to the progress-bar itself
  $process_status_domain: null,  // local global to store jQuery access to the #process-status-domain
  $process_status_text  : null,  // local global to store jQuery access to the #process-status-text
  is_waiting            : false, // local global to store boolean `true` if on order/wait/{code} page
  external_links_hidden : false, // local global to store boolean `true` if orderForm.hideAllOrderExitingLinks()
  distractions_cleared  : false, // local global to store boolean `true` if
                                 // orderForm.removeDistractionsFromOrderForm()
  rate_update_timeout      : null,  // local global to store timeout used in "de-bouncing" the updateRates function
  rate_check_flag          : false, // local global to store if the last update request was just to check the promo code
  minimum_wait_time_passed : false, // local global to store if the minimum wait time has passed on the additional info
  user_waited_on_last_slide: false, // local global to store if the user has waited on the last slide long enough to
                                    // bypass the minimum wait time or filling out the form
  user_waiting_timeout: null,  // local global to store timeout used updating orderForm.user_waited_on_last_slide
  status              : null,  // local global to store the order's current status
  minimum_wait_time   : 0,     // minimum amount of wait time to show that the set up is complete
  conversion_interval : null,  // local global to store conversion interval that will try to track google
                               // conversions until it is successful
  google_conversion_tracked: false, // local global boolean to store whether or not the google conversion script has
                                    // been successfully fired
  finalized       : false,
  currentPromoCode: null,

  setUpTimeoutAndFinalize: function() {
    var time = siteGlobals.values['csrf_life'];
    time -= 2;// 2 minutes should be more than enough time for javascript
              // to load, run, and complete this timeout before the csrf token expires
    time = siteGlobals.secondsToMilliseconds(siteGlobals.minutesToSeconds(time));
    setTimeout(
      function() {
        siteGlobals.ajaxForm(orderForm.$order_form, orderForm.finalize, orderForm.finalize, {}, 'order-new/optional-details/ajax-update');
      },
      time
    );

  },
  finalize               : function() {
    orderForm.finalized  = true;
    window.location.href = '/order-new/finalize/' + $("#code").val();
  },

  goToNextSlide: function() {
    siteGlobals.scrollToElement(orderForm.$process_bar);
    orderForm.$order_form.slick('slickNext'); // this is a simple call to a slick.js function
    orderForm.setUpCurrentSlide();

    //is the user on the "waiting" slide
    //(this is the last slide if there is not an order error
    // if there is an order error this is the second to last slide)
    if ((orderForm.status != 'error' &&
         orderForm.$current_slide.get(0) == orderForm.$order_steps.last().get(0))
        ||
        (orderForm.status == 'error' &&
         orderForm.$current_slide.get(0) == orderForm.$order_steps.toArray().slice(-2)[0])) {

      if (orderForm.user_waiting_timeout != null) {
        // clear the timeout, if one is pending
        clearTimeout(parseInt(orderForm.user_waiting_timeout));
        orderForm.user_waiting_timeout = null;
      }

      //start a timeout to let them continue without filling out the form or waiting the minimum time
      orderForm.user_waiting_timeout = setTimeout(function() {
        orderForm.user_waited_on_last_slide = true;
      }, 5000);
    }
  },

  mostFieldsFilledOut: function() {
    if (orderForm.$order_form.find('.alert.alert-danger').length > 0) {
      return false;
    }
    var softRequiredFields = [
      'phone',
      'cell_phone',
      'alternate_email_address',
      'password',
      // 'street',
      // 'city',
      // 'state',
      // 'postal_code',
      // 'country',
      // 'charge_authorization',
      'security_question',
      'security_answer'
      // 'source'
    ];
    var ii                 = softRequiredFields.length;
    for (var i = 0; i < ii; i++) {
      if (orderForm.$all_inputs.filter('#' + softRequiredFields[i]).val() == '') {
        return false;
      }
    }

    return true;
  },

  updateRates: function() {
    var code = $("#code");
    if (code.length > 0) {
      code = code.val();
      $.ajax({
        url       : '/order-new/optional-details/update-rates',
        type      : "POST",
        data      : {code: code},
        beforeSend: function(xhr) {
          return xhr.setRequestHeader('X-CSRF-TOKEN', siteGlobals.csrfToken)
        },
        success   : function(response) {
          var $priceElements = orderForm.$order_form.find(".price-info-span");
          // var hasPartial     = false;
          // if ($priceElements.filter("partial").length > 0) {
          //   hasPartial = true;
          // }
          for (var key in response) {
            if (response.hasOwnProperty(key)) {
              if (response[key] !== "Plan does not have a monthly rate.") {
                $priceElements.filter("." + key).text(response[key]);
                if (key === 'perMonthMonthly') {
                  $priceElements.closest('label').removeClass('disabled');
                  $("#annual-monthly-2").removeClass('disabled');
                }
              }
              else {
                $priceElements.filter("." + key).text('--');
                if (key === 'perMonthMonthly') {
                  $priceElements.filter("." + key).closest('label').addClass('disabled');
                  $("#annual-monthly-2").addClass('disabled');
                  $("#annual-monthly-1").click();
                  toast({
                    message: 'Monthly billing is not available with this plan.',
                    type   : 'warning',
                    time   : 4500,
                    icon   : 'info'
                  });

                }
              }
            }
          }
        }
      });
    }
  },

  timeoutUpdateRates: function() {
    // this is for the new account order form. #tagSoYouCanFindOtherPromocodeRelatedCode
    var $promoInput = orderForm.$order_form.find("#promo_code");
    if ($promoInput.val().trim().length > 0) {
      siteGlobals.displayValidationErrors($promoInput, ['Checking promo code.'], "info", "circle-notch fa-spin");
    }
    debounce(
      function() {
        var orderCode = orderForm.$order_form.closest("form").find("#code").val();
        if (typeof orderCode === "undefined" || orderCode === null || orderCode.length < 1) {
          // This is not an order in progress, abort
          return false;
        }
        orderForm.rate_check_flag = true;
        orderForm.clearErrors();
        siteGlobals.ajaxForm(orderForm.$order_form, orderForm.orderUpdateSuccessful, orderForm.orderUpdateError, {}, 'order-new/optional-details/ajax-update');
      });
  },


  hideAllOrderExitingLinks: function() {
    if (!orderForm.external_links_hidden) {

      $("#page-footer").remove();

      $("#navbar-collapse, #navbar-content > .navbar-header > button.navbar-toggler, #page-footer")
        .css('visibility', "hidden")
        .css('pointerEvents', "none");
      $("#navbar-content > .navbar-header > a.navbar-brand, #footer-copyright-info > p:first-child > a")
        .attr("href", "#").attr("tabindex", "-1");
      orderForm.external_links_hidden = true;
    }
    siteNavbar.$window.off();
    siteNavbar.compact();
  },

  clearErrors: function() {
    $("#force_domain-wrapper").hide();
    orderForm.$order_form.find('.alert.alert-danger').each(function() {
      $(this).addClass("removing-order-form-alert");
      siteGlobals.animateRemove(this);
    });
  },

  removeDistractionsFromOrderForm: function() {
    $(".order-form-remove").remove();
    if (!orderForm.distractions_cleared) {
      orderForm.hideAllOrderExitingLinks();
      var stripe = $("#order-stripe");
      if (stripe.length > 0) {
        stripe.siblings(":not(footer)").remove();
        stripe.children(".container").children("h3").css('visibility', 'hidden');
        siteGlobals.scrollToX(0);
      }
      orderForm.distractions_cleared = true;
    }
  },


  checkOrderStatus: function() {
    if (orderForm.finalized) {
      return true;
    }
    orderForm.is_waiting = ((orderForm.status != 'error' &&
                             orderForm.$current_slide.get(0) == orderForm.$order_steps.last().get(0))
                            ||
                            (orderForm.status == 'error' &&
                             orderForm.$current_slide.get(0) == orderForm.$order_steps.toArray().slice(-2)[0]));
    var code             = $("#code");
    if (code.length > 0) {
      code = code.val();

      $.ajax({
        url       : '/order-new/optional-details/check-status',
        type      : "POST",
        data      : {code: code},
        beforeSend: function(xhr) {
          return xhr.setRequestHeader('X-CSRF-TOKEN', siteGlobals.csrfToken)
        },
        success   : function(response) {
          var domain           = response['orderStatus']['domain'];
          var status           = response['orderStatus']['statusText'];
          var percentCompleted = response['orderStatus']['progressbar'];
          orderForm.status     = response['orderStatus']['status'];

          orderForm.updateProcessBar(domain, status, percentCompleted);
          if (orderForm.is_waiting && response['orderStatus']['status'] == 'complete') {
            orderForm.finalized = true;
            if (response['autologin'] !== '') {
              setTimeout(function() {
                window.location.assign(response['autologin']);
              }, 0);
            }
          }
          if (response['orderStatus']['status'] === 'error') {
            if (response['thank_you_slide'] !== '' && orderForm.$order_form.find("#thank_you_slide").length === 0) {
              var $temp_container = $('<div></div>');
              $temp_container.html(response['thank_you_slide']);
              var $thank_you_slide = $temp_container.find("#thank_you_slide");
              orderForm.$order_form.slick('slickAdd', $thank_you_slide);
              orderForm.$order_steps = orderForm.$order_form.find('.order-step');
            }
            if (orderForm.is_waiting && response['orderStatus']['status'] === 'error') {
              orderForm.goToNextSlide();
            }
          }
        }
      });
    }
  },

  updateProcessBar: function(domain, status, percentCompleted) {
    percentCompleted = Math.min(percentCompleted, 100);
    percentCompleted = Math.max(percentCompleted, 5);
    if (percentCompleted == 100) {
      if (!orderForm.minimum_wait_time_passed &&
          !orderForm.mostFieldsFilledOut() &&
          !orderForm.user_waited_on_last_slide) {
        percentCompleted = Math.max(parseInt(orderForm.$progress_bar.attr('style').substr(7, 3)), 95);
        // status           = orderForm.$process_status_text.text();
        orderForm.$progress_bar.css('transition-duration', '150s').css('transition-timing-function', 'linear');
      }
      else {
        orderForm.$progress_bar.css('transition-duration', '0.6s').css('transition-timing-function', 'ease');
      }
    }
    setTimeout(function() {
      if (parseInt(orderForm.$progress_bar.attr('style').substr(7, 3)) != percentCompleted) {
        orderForm.$progress_bar.width("calc(" + percentCompleted + "% - 2px)");
      }
      domain = "Setting Up Service: " + domain;
      if (orderForm.$process_status_domain.text() != domain) {
        orderForm.$process_status_domain.text(domain);
      }
      // if (orderForm.$process_status_text.text() != status) {
      //   orderForm.$process_status_text.text(status);
      // }
    }, 50);

  },

  /**
   * @call        => orderForm.prevSlide();
   *
   * @author      => Spencer O'Reilly
   * @date        => May 30, 2016
   *
   * @description => Calls the slick.js slickPrev() function to step back to the previous step in the order process
   *
   */
  prevSlide: function() {
    siteGlobals.scrollToElement(orderForm.$process_bar);
    orderForm.$order_form.slick('slickPrev'); // this is a simple call to a slick.js function
    orderForm.setUpCurrentSlide();
    if (orderForm.user_waiting_timeout != null) {
      // clear the timeout, if one is pending
      clearTimeout(parseInt(orderForm.user_waiting_timeout));
      orderForm.user_waiting_timeout = null;
    }
  },

  /**
   * @call        => orderForm.tryNextSlide();
   *
   * @author      => Spencer O'Reilly
   * @date        => May 30, 2016
   *
   * @description => If the form input elements on the current slide pass validation then
   *                  this calls the slick.js slickNext() function to move on to
   *                  the next step in the order process
   */
  tryNextSlide: function() {
    siteGlobals.$body.css("cursor", "progress");
    orderForm.clearErrors();
    orderForm.$order_form.find("#promo_code").trigger("input");

    siteGlobals.ajaxForm(orderForm.$order_form, orderForm.orderUpdateSuccessful, orderForm.orderUpdateError, {next_slide: "yes"}, 'order-new/optional-details/ajax-update');
  },

  /**
   * @call        => orderForm.orderUpdateSuccessful();
   *
   * @author      => Spencer O'Reilly
   * @date        => September 14, 2016
   *
   * @description => Function called if the ajax call to update the
   *                 order details was successful. This should
   *                 update the order status on screen, and
   *                 allow us to continue to filling out
   *                 information.
   *
   * @param response
   */
  orderUpdateSuccessful: function(response) {
    var $promoInput = orderForm.$order_form.find("#promo_code");
    if ($promoInput.val().trim().length > 0) {
      var promoCodeText = 'Valid promo code, complete your order to apply.';
      if (typeof response.promo_code_details !== "undefined" && response.promo_code_details.length > 0) {
        promoCodeText = response.promo_code_details;
      }
      var promoCodeCheckbox = null;
      if (typeof response.promo_code_checkbox !== "undefined" && response.promo_code_checkbox !== null && response.promo_code_checkbox.length > 0) {
        promoCodeCheckbox = response.promo_code_checkbox;
      }
      siteGlobals.displayValidationErrors($promoInput, [promoCodeText], "success", "check");
      if (promoCodeCheckbox === null) {
        $("#promo-code-confirm-checkbox-wrapper").hide();
      }
      else {
        $("#promo-code-confirm-checkbox-wrapper").show();
        $("#promo-code-confirm-checkbox-text").html(promoCodeCheckbox);
      }
    }
    else {
      $("#promo-code-confirm-checkbox-wrapper").hide();
    }
    orderForm.updateRates();
    siteGlobals.$body.css("cursor", "default");
    if (typeof response !== 'undefined') {
      orderForm.updateProcessBar(response['order_status']['domain'],
        response['order_status']["statustext"],
        response['order_status']['progressbar']);
    }
    if ((orderForm.status !== 'error' && orderForm.$current_slide.get(0) !== orderForm.$order_steps.last().get(0))
        ||
        (orderForm.status === 'error' && orderForm.$current_slide.get(0) !== orderForm.$order_steps.toArray().slice(-2)[0])) {
      orderForm.is_waiting = false;
      if (!orderForm.rate_check_flag) {
        orderForm.goToNextSlide();
      }
    }
    else {
      orderForm.is_waiting = true;
      if (orderForm.minimum_wait_time_passed ||
          orderForm.mostFieldsFilledOut() ||
          orderForm.user_waited_on_last_slide) {
        if (orderForm.status === 'error') {
          orderForm.goToNextSlide();
        }
      }
    }
    orderForm.rate_check_flag = false;
  },

  /**
   * @call        => orderForm.orderUpdateError();
   *
   * @author      => Spencer O'Reilly
   * @date        => September 14, 2016
   *
   * @description => Function called if the ajax call to update the order details fails. This gets called hopefully
   *   only when a validation error is thrown, in which case, we need to display that error.
   *
   * @param response
   */
  orderUpdateError: function(response) {
    orderForm.updateRates();
    siteGlobals.$body.css("cursor", "default");
    if (parseInt(response.status) === 422) {
      if (siteGlobals.consoleLog) {
        console.log('ERROR 422: Form Validation Failed.');
      }
      var focusIsSet = false;
      orderForm.$all_inputs.each(function() {
        var $this                = $(this);
        var showErrors           = true;
        var setFocusToFirstInput = false;
        var name                 = $this.attr('name');
        if (response.responseText.hasOwnProperty(name)) {
          if (!focusIsSet) {
            if ($.contains(orderForm.$current_slide[0], $this[0])) {
              focusElementPreventScroll($this);
              $this.popover('hide');
              focusIsSet = true;
            }
            else {
              if (!orderForm.rate_check_flag) {
                var errorSlideIndex = orderForm.$order_steps.index($this.closest('.order-step')[0]);
                var currentSlide    = orderForm.$order_form.slick("getCurrent");
                var goToSlide       = errorSlideIndex;
                // don't skip forward in the form to show an error for a field they haven't even seen yet!
                if ((currentSlide + 1) <= errorSlideIndex) {
                  goToSlide            = currentSlide + 1;
                  setFocusToFirstInput = true;
                  showErrors           = false; // don't show an error on a slide we're not looking at...
                }
                orderForm.$order_form.slick('slickGoTo', goToSlide, false);
                orderForm.setUpCurrentSlide(setFocusToFirstInput);
                if (showErrors) {
                  focusElementPreventScroll($this);
                  $this.popover('hide');
                  focusIsSet = true;

                }

              }

            }


          }

          if (showErrors) {
            orderForm.handleOrderValidationError($this, response.responseText[name]);
          }


        }
      });
    }
    if (parseInt(response.status) === 419) {
      orderForm.handleOrderValidationError($(orderForm.$all_inputs[0]), [response.responseText.message]);
    }
    orderForm.rate_check_flag = false;
  },


  /**
   * @call        => orderForm.setUpCurrentSlide();
   *
   * @author      => Spencer O'Reilly
   * @date        => June 21, 2016
   *
   * @description => Sets up tab-indexes, and prepares validation on blur.
   *
   * @param        setFocus (BOOLEAN) defaults to true, if set to false the current focus will not be affected.
   */
  setUpCurrentSlide: function(setFocus) {
    setFocus                  = typeof setFocus === "undefined" ? true : setFocus;
    var index                 = orderForm.$order_form.slick('slickCurrentSlide');
    orderForm.$current_slide  = $(orderForm.$order_form.find('li.order-step').get(index));
    orderForm.$current_inputs = orderForm.$current_slide
                                         .find('input:not([type="button"]):not([type="reset"]):not([type="submit"])[name]:not([name=""]), select[name]:not([name=""]), textarea[name]:not([name=""])');

    var $inputsToTab = orderForm.$current_slide.find('input:not(:hidden), select:not(:hidden), textarea:not(:hidden), .order-next');
    $("[tabindex]:not([tabindex='-1'])").removeAttr("tabindex");
    orderForm.$all_inputs.attr("tabindex", -1);
    var skippedCount = 0;
    $inputsToTab.each(function(i) {
      var $this = $(this);
      if (siteGlobals.isVisible($this.get(0))) {
        $this.attr("tabindex", i + 1 - skippedCount);
        // the plus one allows us to start counting at 1 instead of 0
        // this in no way affects the value of i
      }
      else {
        skippedCount += 1;
        $this.attr("tabindex", -1);
      }
    });
    if (setFocus) {
      var $firstInput = $($inputsToTab.get(0));
      if ($firstInput.attr('id') !== 'full_name') {
        focusElementPreventScroll($firstInput);
      }
    }
    var nextTabindex = $inputsToTab.length - skippedCount + 1;
    orderForm.$current_slide.find('.order-prev').attr("tabindex", nextTabindex);
    if (typeof conditionalJsAuto !== "undefined") {
      conditionalJsAuto(orderForm.$current_slide);
    }
  },

  /**
   * @call        => orderForm.handleOrderResponseSuccess();
   *
   * @author      => Spencer O'Reilly
   * @date        => June 22, 2016
   *
   * @description =>
   *
   */
  handleOrderResponseSuccess: function(response) {
    $('.rm-if-js').remove();
    // {{-- clicktracking --}}
    if (typeof improvely !== "undefined") {
      improvely.init('isparks', 1);
      improvely.conversion({
        goal     : 'free trial',
        revenue  : 0,
        reference: response['code']
      });
    }
    if (typeof rdt !== "undefined") {
      rdt('track', 'SignUp');
    }
    if (typeof gtag == 'function') {
      gtag("event", "sign_up");
      gtag('event', 'conversion', {
        'send_to': 'AW-1054540440/AzHUCJ-a4osBEJiF7PYD'
      });
      orderForm.google_conversion_tracked = true;
    }
    else {
      orderForm.conversion_interval = setInterval(function() {
        if (!orderForm.google_conversion_tracked && typeof gtag == 'function') {
          gtag("event", "sign_up");
          gtag('event', 'conversion', {
            'send_to': 'AW-1054540440/AzHUCJ-a4osBEJiF7PYD'
          });
          orderForm.google_conversion_tracked = true;
          clearInterval(parseInt(orderForm.conversion_interval));
        }
      }, 2000);
    }
    orderForm.$current_slide.find('.alert.alert-danger').each(function() {
      siteGlobals.animateRemove(this);
    });
    orderForm.$order_form.slick('slickNext'); // this is a simple call to a slick.js function
    orderForm.setUpCurrentSlide();
    siteGlobals.scrollToElement(orderForm.$process_bar);
    orderForm.$process_bar.addClass('active');
    orderForm.$order_form.closest('form').attr('action', '/order-new/optional-details');
    var $methodSimulation = $('<input>').attr({
      type : 'hidden',
      id   : '_method',
      name : '_method',
      value: 'PATCH'
    });
    var $code             = $('<input>').attr({
      type : 'hidden',
      id   : 'code',
      name : 'code',
      value: response['code']
    });
    if (typeof response !== "undefined" && typeof response['orderlog'] !== "undefined" && typeof response['orderlog']['promocode'] !== "undefined" &&
        response['orderlog']['promocode'].length > 0) {
      orderForm.$order_form.find("#promo_code").val(response['orderlog']['promocode']);
    }
    orderForm.$order_real_form.append([$methodSimulation, $code]);
    orderForm.removeDistractionsFromOrderForm();
    orderForm.$order_form.closest('form').submit(function(e) {
      orderForm.tryNextSlide();
      if (e.preventDefault) {
        e.preventDefault();
      }
      if (e.stopPropagation) {
        e.stopPropagation();
      }
    });
    orderForm.$first_submit.remove();
    setTimeout(function() {
      orderForm.minimum_wait_time_passed = true;
    }, orderForm.minimum_wait_time);
    orderForm.setUpTimeoutAndFinalize();

    // Putting this on a timeout so it doesn't happen during the animation.
    setTimeout(function() {
      // Calling this once makes slick automatically resize itself when the window changes size.
      var prevFocus = $(":focus");
      orderForm.$order_form.slick('refresh');
      $('.rm-if-js').remove();
      // The first step can't be returned to, make it invisible to disable tabbing to elements within it.
      $("#first-step-of-order-form").css({visibility: "hidden"});
      prevFocus.focus();
    }, 500);
  },

  /**
   * @call        => orderForm.handleOrderResponseFailure();
   *
   * @author      => Spencer O'Reilly
   * @date        => June 20, 2016
   *
   * @description =>
   *
   */
  handleOrderResponseFailure: function(response) {
    orderForm.$first_submit.html(orderForm.$first_submit.attr("data-original-text")).prop("disabled", false);
    if (response.status == 422) {
      if (siteGlobals.consoleLog) {
        console.log('ERROR 422: Form Validation Failed.');
      }
      var focusIsSet = false;
      orderForm.$current_inputs.each(function() {
        var $this = $(this);
        var name  = $this.attr('name');
        if (response.responseText.hasOwnProperty(name)) {
          if (!focusIsSet) {
            focusElementPreventScroll($this);
            focusIsSet = true;
          }

          orderForm.handleOrderValidationError($this, response.responseText[name]);

        }
      });
    }
    if (response.status == 419) {
      if (siteGlobals.consoleLog) {
        console.log('ERROR 419: Page expired.');
      }
      orderForm.$current_inputs.each(function() {
        var $this = $(this);
        orderForm.handleOrderValidationError($this, [response.responseText.message]);
        return false;
      });
    }
  },

  handleOrderValidationError: function(input, error) {

    if (input.attr("name") === "domain") {
      error.forEach(function(singleError) {
        if (singleError.indexOf("mail server") !== -1) {
          $("#force_domain-wrapper").show();
        }
      });
    }

    if ($(".removing-order-form-alert").length) {
      setTimeout(function() {
        siteGlobals.displayValidationErrors(input, error);
      }, 500);
    }
    else {
      siteGlobals.displayValidationErrors(input, error);
    }

  },

  /**
   * @call        => orderForm.handleOptionalDetailsResponse();
   *
   * @author      => Spencer O'Reilly
   * @date        => June 14, 2016
   *
   * @description => Set up the optional details steps. Alter functionality of order button to use ajax.
   *
   */
  handleOptionalDetailsResponse: function(response) {
    var $tempContainer = $('<div></div>');
    $tempContainer.html(response['process-bar']);
    orderForm.$process_bar = $tempContainer.find("#full-process-bar");
    orderForm.$process_bar_container.prepend(orderForm.$process_bar);

    $tempContainer.html(response['optional-details']);
    var $li = $tempContainer.children('li');
    $li.each(function(index) {
      var $this = $(this);
      if (index == 0) {
        $this.find('.order-prev').remove();
      }
      orderForm.$order_form.slick('slickAdd', $this);
    });
    $tempContainer.html(""); // just in extra effort to clear cache
    //noinspection JSUnusedAssignment
    $tempContainer = undefined;

    orderForm.reInitiate();
  },

  /**
   * @call        => orderForm.reInitiate();
   *
   * @author      => Spencer O'Reilly
   * @date        => July 29, 2016
   *
   * @description =>  Initializes starting values, saves jQuery access to local globals, and sets event handlers.
   *                  For additional optional info may be done after page load.
   *
   */
  reInitiate: function() {
    siteGlobals.reInitiate();

    orderForm.$second_submit         = orderForm.$order_form.find('#second-submit');
    orderForm.$all_inputs            =
      orderForm.$order_form.find("input, select, textarea, button, .order-prev, .order-next");
    orderForm.$progress_bar          = $('.progress-bar');
    orderForm.$process_status_domain = $('#process-status-domain');
    orderForm.$process_status_text   = $('#process-status-text');
    orderForm.$order_steps           = orderForm.$order_form.find('.order-step');

    orderForm.setUpCurrentSlide();
    var $orderPrev = $('.order-prev');
    var $orderNext = $('.order-next');

    $orderPrev.unbind('click');
    $orderPrev.click(function() {
      orderForm.prevSlide()
    });

    $orderNext.unbind('click');
    $orderNext
      .click(function() {
        orderForm.tryNextSlide()
      });

    orderForm.$first_submit.click(function(e) {
      if (e.preventDefault) {
        e.preventDefault();
      }

      if (e.stopPropagation) {
        e.stopPropagation();
      }
      orderForm.$first_submit.html("<i class='fa fa-circle-notch fa-spin'></i>").prop("disabled", true);

      // var $validationErrors = orderForm.$order_form.find( '.alert.alert-danger:not(js-set)' );
      // if ( $validationErrors.length == 0 )
      // {
      orderForm.clearErrors();
      siteGlobals.ajaxForm(orderForm.$order_form, orderForm.handleOrderResponseSuccess, orderForm.handleOrderResponseFailure);
      // }
      // else
      // {
      siteGlobals.scrollToElement(orderForm.$process_bar_container);
      // }
      return false;
    });
    siteGlobals.$document.on('input change blur keyup', function(e) {
      var code = (e.keyCode ? e.keyCode : e.which);
      if (parseInt(code) === 13) {
        var $focusedElement = $(document.activeElement);
        if ($focusedElement.length === 1) {
          if ($focusedElement.hasClass('order-next')) {
            orderForm.tryNextSlide();
          }
          else if ($focusedElement.hasClass('order-prev')) {
            orderForm.prevSlide();
          }
        }
      }
    });
    $('input[type=radio][name=billing_method]').on('input', function() {
      orderForm.setUpCurrentSlide(false);
    });
    var $promo_code = orderForm.$order_form.find("#promo_code");
    $promo_code.on('input', function() {
      if ($(this).val() === orderForm.currentPromoCode) {
        return true;
      }
      orderForm.currentPromoCode = $(this).val();
      $("#promo-code-confirm-checkbox-wrapper").hide();
      orderForm.timeoutUpdateRates();
    });
    plan_selector.initiate();
    if (plan_selector.$plan_selector !== null) {
      plan_selector.$plan_selector.on('input', function() {
        orderForm.timeoutUpdateRates();
      });
    }


  },


  /**
   * @call        => orderForm.initiate();
   *
   * @author      => Spencer O'Reilly
   * @date        => May 30, 2016
   *
   * @description => Initializes starting values, saves jQuery access to local globals, and sets event handlers.
   *
   */
  initiate: function() {
    orderForm.$order_form = $("#order-slider"); // what?? that's not the form! bad name for this variable. Do not alter the dom of this, it
                                                // messes up slick.
    orderForm.$order_real_form = orderForm.$order_form.closest("form");
    if (orderForm.$order_form.length > 0) {
      orderForm.$first_submit          = orderForm.$order_form.find('#first-submit');
      orderForm.$second_submit         = orderForm.$order_form.find('#second-submit');
      orderForm.$order_steps           = orderForm.$order_form.find('.order-step');
      orderForm.$process_bar           = $('#full-process-bar');
      orderForm.$progress_bar          = orderForm.$process_bar.find('.progress-bar');
      orderForm.$process_status_domain = orderForm.$process_bar.find('#process-status-domain');
      orderForm.$process_status_text   = orderForm.$process_bar.find('#process-status-text');

      orderForm.$process_bar_container = $('#process-bar-spacer');
      orderForm.$all_inputs            =
        orderForm.$order_form.find("input, select, textarea, button, .order-prev, .order-next");

      $("#order-scroll-button").click(function() {
        siteGlobals.scrollToElement(orderForm.$process_bar_container)
      });
      $("#domain").on('input change blur keyup', function() {
        $(this).val($(this).val().trim());
        if ($(this).val().substring(0, 5).toLowerCase() !== "mail.") {
          $("#force_domain-wrapper").hide();
          $("#force_domain-checkbox").prop("checked", false);
        }
      });
      orderForm.setUpCurrentSlide();

      $('.order-prev')
        .click(function() {
          orderForm.prevSlide()
        });

      $('.order-next')
        .click(function() {
          orderForm.tryNextSlide()
        });
      if (siteGlobals.url.indexOf("/optional-details/") == -1 && siteGlobals.url.indexOf("order/wait/") == -1) {
        siteGlobals.ajaxLoad('order-new/optional-details', orderForm.handleOptionalDetailsResponse);
      }
      else {
        var $slides = $(orderForm.$order_form.find('.order-step'));
        $slides.eq(1).find('.order-prev').remove();
        var $errors = orderForm.$order_form.find('.error-bag');
        if ($errors.length > 0) {
          var $firstError = $errors.eq(0);
          var $slide      = $firstError.closest('.order-step');
          var slideIndex  = parseInt($slides.index($slide));
          orderForm.$order_form.slick('slickGoTo', slideIndex, false);
          orderForm.setUpCurrentSlide(false);

          var $inputFocus = $("#" + $firstError.attr('data-error-bag-for'));
          if ($inputFocus.length == 1) {
            focusElementPreventScroll($inputFocus);
          }

        }
      }
      if (siteGlobals.url.indexOf("optional-details") != -1) {
        orderForm.setUpTimeoutAndFinalize();
        orderForm.reInitiate();
        orderForm.$order_form.closest('form').submit(function(e) {
          orderForm.tryNextSlide();
          if (e.preventDefault) {
            e.preventDefault();
          }

          if (e.stopPropagation) {
            e.stopPropagation();
          }

        });
        orderForm.hideAllOrderExitingLinks();
        setTimeout(function() {
          orderForm.minimum_wait_time_passed = true;
        }, orderForm.minimum_wait_time);
      }
      /*var statusInterval = */
      setInterval(orderForm.checkOrderStatus, 2000);
    }
  }
};

orderForm.initiate();


$("#no-domain-order-link").click(function(e) {


  var name  = $("#full_name").val();
  var email = $("#email_address").val();

  if (name.length < 1 && email.length < 1) {
    return;
  }

  var url   = $(this).attr("href");
  var query = "?";
  if (name.length > 0) {
    query += "name=" + name;
    if (email.length > 0) {
      query += "&";
    }
  }
  if (email.length > 0) {
    query += "email=" + email;
  }

  e.preventDefault();
  window.location.href = url + query;
});

var focusPreventScrollSupported = false;

document.createElement('div').focus({
  get preventScroll()
  {
    focusPreventScrollSupported = true;

    return false;
  },
});

var focusElementPopoverTimeout = null;
var focusElementTimeout        = null;

function focusElementPreventScroll($element)
{
  clearTimeout(focusElementTimeout);
  clearTimeout(focusElementPopoverTimeout);
  $('.popover').popover('hide');
  if (!$element.length) {
    return;
  }
  if (focusPreventScrollSupported) {
    $element[0].focus({preventScroll: true});
    $element.popover("hide");
    focusElementPopoverTimeout = setTimeout(function() {
      $element.popover("show");
    }, 500);
  }
  else {
    focusElementTimeout = setTimeout(function() {
      $element[0].focus();
    }, 500);
  }
}


$("#test-order-button").click(function() {
  var currentDate = new Date();
  var seconds     = currentDate.getSeconds();
  var minute      = currentDate.getMinutes();
  var hour        = currentDate.getHours();
  var day         = currentDate.getDate();
  var month       = currentDate.getMonth() + 1;
  var year        = currentDate.getFullYear();
  var testName    = "Test-" + year + "-" + month + "-" + day + "-" + hour + "-" + minute + "-" + seconds;

  var $fullName     = $("#full_name");
  var $emailAddress = $("#email_address");
  var $domain       = $("#domain");

  if ($fullName.val().length < 1) {
    $fullName.val("Test Man");
  }
  if ($emailAddress.val().length < 1) {
    $emailAddress.val(testName + "@example.com");
  }
  if ($domain.val().length < 1) {
    $domain.val(testName + ".com");
  }
  $("#first-submit").click();
});

