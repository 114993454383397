var billingPreferencesAlt = {
  $billing_preference_alt: null,
  rate_update_timeout    : null,
  code                   : null,
  $promo_code_field      : null,
  ratesBeingChecked      : false,
  currentPromoCode       : null,

  updateRates: function() {
    // this is for existing / reseller account order forms. #tagSoYouCanFindOtherPromocodeRelatedCode
    if (billingPreferencesAlt.$promo_code_field.val().trim().length > 0) {
      siteGlobals.displayValidationErrors(billingPreferencesAlt.$promo_code_field, ['Checking promo code.'], "info", "circle-notch fa-spin");
    }

    debounce(function() {
      var data = {
        code      : billingPreferencesAlt.code,
        promo_code: billingPreferencesAlt.$promo_code_field.val()
      };
      if (billingPreferencesAlt.$plan_code.length > 0) {
        data['plan_code'] = billingPreferencesAlt.$plan_code.val();
      }
      if (billingPreferencesAlt.code !== null) {
        billingPreferencesAlt.ratesBeingChecked = true;
        $.ajax({
          url       : siteGlobals.url + '/update-rates',
          type      : "POST",
          data      : data,
          beforeSend: function(xhr) {
            return xhr.setRequestHeader('X-CSRF-TOKEN', siteGlobals.csrfToken)
          },
          success   : function(response) {
            var $priceElements = billingPreferencesAlt.$billing_preference_alt.find(".price-info-span");
            for (var key in response) {
              if (response.hasOwnProperty(key)) {
                if (response[key] !== "Plan does not have a monthly rate.") {
                  $priceElements.filter("." + key).text(response[key]);
                  if (key === 'perMonthMonthly') {
                    $priceElements.closest('label').removeClass('disabled');
                    $("#annual-monthly-2").removeClass('disabled');
                  }
                }
                else {
                  $priceElements.filter("." + key).text('--');
                  if (key === 'perMonthMonthly') {
                    $priceElements.filter("." + key).closest('label').addClass('disabled');
                    $("#annual-monthly-2").addClass('disabled');
                    $("#annual-monthly-1").click();
                    toast({
                      message: 'Monthly billing is not available with this plan.',
                      type   : 'warning',
                      time   : 4500,
                      icon   : 'info'
                    });
                  }
                }
              }
            }
            // This code is for existing account orders / reseller account orders.
            var promoError = false;
            if (!response['promo_code_is_valid']) {
              siteGlobals.displayValidationErrors(billingPreferencesAlt.$promo_code_field, ['Sorry, no promotional offer was found for that code.']);
              $("#promo-code-confirm-checkbox-wrapper").hide();
              promoError = true;
            }
            if (!response['promo_code_plan_is_valid']) {
              siteGlobals.displayValidationErrors(billingPreferencesAlt.$promo_code_field, ['This plan does not qualify for this promotional offer.']);
              $("#promo-code-confirm-checkbox-wrapper").hide();
              promoError = true;
            }
            if (!response['promo_code_domain_is_valid']) {
              siteGlobals.displayValidationErrors(billingPreferencesAlt.$promo_code_field, ['Your domain does not qualify for this promotional offer.']);
              $("#promo-code-confirm-checkbox-wrapper").hide();
              promoError = true;
            }
            if (!response['promo_code_within_limit']) {
              siteGlobals.displayValidationErrors(billingPreferencesAlt.$promo_code_field, ['Your account has already reached the limit for this promo code.']);
              $("#promo-code-confirm-checkbox-wrapper").hide();
              promoError = true;
            }
            if (!promoError) {

              if (billingPreferencesAlt.$promo_code_field.val().trim().length < 1) {
                siteGlobals.clearValidationErrors(billingPreferencesAlt.$promo_code_field);
              }
              else {
                var promoCodeText = 'Valid promo code, complete your order to apply.';
                if (typeof response.promo_code_details !== "undefined" && response.promo_code_details.length > 0) {
                  promoCodeText = response.promo_code_details;
                }
                var promoCodeCheckbox = null;
                if (typeof response.promo_code_checkbox !== "undefined" && response.promo_code_checkbox !== null
                    && response.promo_code_checkbox.length > 0) {
                  promoCodeCheckbox = response.promo_code_checkbox;
                }
                siteGlobals.displayValidationErrors(billingPreferencesAlt.$promo_code_field, [promoCodeText], "success", "check");
                if (promoCodeCheckbox === null) {
                  $("#promo-code-confirm-checkbox-wrapper").hide();
                }
                else {
                  $("#promo-code-confirm-checkbox-wrapper").show();
                  $("#promo-code-confirm-checkbox-text").html(promoCodeCheckbox);
                }
              }
            }
            billingPreferencesAlt.ratesBeingChecked = false;
          },
        });
      }
    });
  },


  initiate: function() {
    billingPreferencesAlt.$billing_preference_alt = $("#billing-preferences-alt");
    if (billingPreferencesAlt.$billing_preference_alt.length > 0) {
      billingPreferencesAlt.code              = $("#code").val();
      billingPreferencesAlt.$promo_code_field = billingPreferencesAlt.$billing_preference_alt.find("#promo_code");
      billingPreferencesAlt.$plan_code        = billingPreferencesAlt.$billing_preference_alt.find("#plan-select-box");
      if (billingPreferencesAlt.$plan_code.length > 0) {
        billingPreferencesAlt.$plan_code.on('change', function() {
          billingPreferencesAlt.updateRates();
        });
      }
      billingPreferencesAlt.$promo_code_field.on('input', function() {
        if ($(this).val() === billingPreferencesAlt.currentPromoCode) {
          return true;
        }
        $("#promo-code-confirm-checkbox-wrapper").hide();
        billingPreferencesAlt.updateRates();
        billingPreferencesAlt.currentPromoCode = $(this).val();
      }).trigger("input");
    }
  }
};

billingPreferencesAlt.initiate();

