function showContactMethod(method)
{
  if (method != 'contact_call') {
    $('#contact_call').css('display', 'none');
  }

  if (method != 'contact_email') {
    $('#contact_email').css('display', 'none');
  }

  if (method === 'contact_call') {
    $("#contact-call").modal("show");
  }


  $('#' + method).css('display', 'block');

  siteGlobals.reInitiate();
  var x = window.scrollX, y = window.scrollY;
  $("#subject").focus();
  window.scrollTo(x, y);

  siteGlobals.scrollToElement('#contact-method-buttons');

}

// var categories
// Categories, Subcategories, and Placeholders
var categories = {
  'general_inquiry' : {
    'text'         : "General Inquiry",
    'showsub'      : false,
    'subcategories': {
      'default': {
        'text'       : undefined,
        'placeholder': "Please describe your problem or question in detail. Where applicable, provide steps on how to reproduce the issue."
      }
    }
  },
  'getting_started' : {
    'text'         : "Getting Started",
    'showsub'      : true,
    'subcategories': {
      'setup_help'   : {
        'text'       : "Service setup help",
        'placeholder': undefined
      },
      'outbound_smtp': {
        'text'       : "Outbound SMTP setup",
        'placeholder': undefined
      }
    }
  },
  'receiving_mail'  : {
    'text'         : "Receiving Mail",
    'showsub'      : true,
    'subcategories': {
      'no_certain_messages': {
        'text'            : "Can't receive certain message(s)*",
        'placeholder'     : "Please provide the To/From/Subject/Date of the expected message.",
        'require_msg_link': true // keep in sync with array in ContactRequest class
      },
      'no_mail'            : {
        'text'       : "Can't receive any mail",
        'placeholder': "Please provide any error messages you have seen, and if you have made any recent changes to your mail host settings."
      },
      'slow_mail'          : {
        'text'            : "Slow delivery or mail deferring*",
        'placeholder'     : "Please provide the To/From/Subject/Date of the delayed message, or the raw message header.  The raw message header is best for us, and quicker for us to troubleshoot.",
        'require_msg_link': true // keep in sync with array in ContactRequest class
      },
      'not_in_quarantine'  : {
        'text'       : "Message is not in the quarantine",
        'placeholder': "Please provide as many details about the message as possible. (To/From/Subject/Date)",
        'hide'       : ["#select-message-alert"],
        'show'       : ["#missing-message-alert"]
      }
    }
  },
  'sending_mail'    : {
    'text'         : "Sending Mail",
    'showsub'      : true,
    'subcategories': {
      'cant_send'     : {
        'text'       : "Can't send any mail",
        'placeholder': undefined
      },
      'outbound_setup': {
        'text'       : "Outbound SMTP setup",
        'placeholder': undefined
      }
    }
  },
  'filtering_issues': {
    'text'         : "Filtering Issues",
    'showsub'      : true,
    'subcategories': {
      'better_tuning'  : {
        'text'       : "Tuning for better accuracy",
        'placeholder': undefined
      },
      'receiving_spam' : {
        'text'            : "Spam message received",
        'placeholder'     : undefined,
        'require_msg_link': true // keep in sync with array in ContactRequest class

        // 'placeholder': "Please provide at least one example of a Spam Message you are seeing (either the To/From/Subject/Date, or raw header)",
      },
      'false_positives': {
        'text'            : "Clean messages quarantined*",
        'placeholder'     : "Please provide at least one example of the false positive message you are seeing (either the To/From/Subject/Date, or the raw header)",
        'require_msg_link': true // keep in sync with array in ContactRequest class
      },
      'general'        : {
        'text'       : "General filtering questions",
        'placeholder': undefined
      }
    }
  },
  'other'           : {
    'text'         : "Other",
    'showsub'      : true,
    'subcategories': {
      'reseller_questions': {
        'text'       : "Reseller Questions",
        'placeholder': undefined
      },
      'cp_questions'      : {
        'text'       : "Control panel questions",
        'placeholder': undefined
      },
      'bug'               : {
        'text'       : "Found bug",
        'placeholder': "Please provide detailed steps on how to replicate the bug you are seeing."
      }
    }
  }
};


// populateCategories
//
// @param selectPicker select
// @param options JSON
// @param show bool
// @returns void
// Initialize the main categories
function populateCategories(selectPicker, options, value, subVal)
{
  var subCategory = false;
  if ($(selectPicker).attr("id") === "subcategory") {
    subCategory = true;
  }
  var categoryExists          = typeof value !== "undefined" && value !== null && value.length && typeof categories[value] !== "undefined";
  selectPicker.options.length = 0;
  var show;
  if (categoryExists) {
    show = categories[value]["showsub"];
  }
  else {
    show = typeof value !== "undefined" && value !== null && value.length;
  }
  var selected = false;

  // Setup the real options
  for (index in options) {
    var new_opt         = document.createElement("option");
    new_opt.textContent = options[index].text;
    new_opt.value       = index;
    new_opt.disabled    = false;
    if (new_opt.value === value) {
      new_opt.selected = true;
      selected         = true;
    }


    selectPicker.appendChild(new_opt);
  }

  // Enter the "Select an option" option
  var new_option         = document.createElement("option");
  new_option.textContent = " -- select an option -- ";
  new_option.disabled    = true;
  if (!selected) {
    new_option.selected = true;
  }
  new_option.value = 'disabled';
  selectPicker.insertBefore(new_option, selectPicker.firstChild);

  $('#' + selectPicker.id).selectpicker('refresh');

  // Should we show the subcategories box?
  if (!show) {
    //$('#subject_details').removeClass('hidden');
    $('#subcategory_details').addClass('hidden');
  }
  else {
    //$('#subject_details').addClass('hidden');
    $('#subcategory_details').removeClass('hidden');
  }
  if (categoryExists && !subCategory && show) {
    populateCategories(document.getElementById("subcategory"), categories[value]["subcategories"], subVal);
  }
}

// updateSubmit
//
// @returns void
function updateSubmit()
{
  var subcat       = document.getElementById('subcategory');
  var createTicket = document.getElementById("received-spam-create-ticket").checked;
  // If they want to report missed spam, disable the submit button
  // If subcategory = receiving_spam
  if(subcat === null){
    return;
  }
  if (subcat.value === 'receiving_spam') {

    $("#message-attachment-wrapper").hide();
    $("#specific-messages-box").hide();
    $("#report-spam-box").show();


    if (createTicket) {
      $("#message_area").removeClass("d-none");
      $("#submit_button").removeClass("disabled");
      $("#contact-submit-button-wrapper").show();
      $("#report-spam-identify-message-wrapper").show();
    }
    else {
      $("#message_area").addClass("d-none");
      $("#contact-submit-button-wrapper").hide();
      $("#submit_button").addClass("disabled");
      $("#report-spam-identify-message-wrapper").hide();
    }
  }
  else {
    // Otherwise, make sure submit button is good
    $("#message_area").removeClass("d-none");
    $("#message-attachment-wrapper").show();
    $("#report-spam-box").hide();
    $("#report-spam-identify-message-wrapper").hide();


    $("#specific-messages-box").show();
    $("#submit_button").removeClass("disabled");
    $("#contact-submit-button-wrapper").show();
  }
}

// updateSubject
//
// @returns void
// Update the subject of the message
function updateSubject()
{
  var subject     = document.getElementById('subject');
  var category    = document.getElementById('category');
  var subcategory = document.getElementById('subcategory');

  var pos = subject.value.indexOf(":");
  if (pos == -1) {
    pos = 0;
  }
  else {
    pos += 2;
  }
  var previous_subject = subject.value.substr(pos);

  if (category.options[category.selectedIndex].disabled) {
    previous_subject = "";
  }

  if (category.value != "general_inquiry") {
    if (subcategory.selectedIndex > 0) {
      subject.value =
        category.options[category.selectedIndex].text + " - " + subcategory.options[subcategory.selectedIndex].text + ": " + previous_subject;
    }
    else {
      subject.value = category.options[category.selectedIndex].text + ": " + previous_subject;
    }
  }
  else {
    subject.value = previous_subject;
  }

  updateSubmit();
}

$(document).ready(function() {
  if ($("#received-spam-create-ticket").length) {
    $("#received-spam-create-ticket").on("input click change", function() {
      updateSubmit();
    });
    updateSubmit();
  }
});


// updatePlaceholder
//
// @param string subcat
// @returns void
// This populates the Message's placeholder for request data.
function updatePlaceholder(subcat)
{
  $(".sub-category-show-default").show();
  $(".sub-category-hide-default").hide();

  var message         = document.getElementById('message');
  var category        = document.getElementById('category');
  var new_placeholder = categories[category.value]['subcategories'][subcat.value]['placeholder'];
  var hide            = categories[category.value]['subcategories'][subcat.value]['hide'];
  var show            = categories[category.value]['subcategories'][subcat.value]['show'];

  if (typeof new_placeholder != 'undefined') {
    message.placeholder = new_placeholder;
  }
  else {
    message.placeholder = categories['general_inquiry']['subcategories']['default']['placeholder'];
  }

  if (categories[category.value]['subcategories'][subcat.value].require_msg_link) {
    $("#require-msg-link").show();
    $("#no-require-msg-link").hide();
  }
  else {
    $("#require-msg-link").hide();
    $("#no-require-msg-link").show();
  }

  if (typeof hide !== "undefined") {
    hide.forEach(function(selector) {
      $(selector).hide();
    });
  }
  if (typeof show !== "undefined") {
    show.forEach(function(selector) {
      $(selector).show();
    });
  }

  updateSubject();
}

if ($("#ContactSupport").length) {
  var ua      = window.navigator.userAgent;
  var msie    = ua.indexOf('MSIE ');
  var trident = ua.indexOf('Trident/');

  // Check if  IE 11 or down is used, if so, don't use the category field
  if (msie > 0 || trident > 0) {
    $('#category_details').addClass('hidden');
  }
  else {
    populateCategories(document.getElementById('category'), categories, $("#prev-category").val(), $("#prev-subcategory").val());
  }
}


// Auto Suggestions
var messageArea    = document.getElementById('subject');
var suggestionArea = document.getElementById('suggestion_area');
var timeout        = null;
if (messageArea !== null) {
  messageArea.oninput = function(e) {
    clearTimeout(timeout);
    $(suggestionArea)
      .html("<div style=\"height: 100%; width: 100%;\" class=\"d-flex align-items-center justify-content-center\"><h4 ><i class='fa fa-circle-notch fa-spin'></i></h4></div>");
    timeout = setTimeout(function() {
      $.post("/support/suggest",
        {
          content: messageArea.value
        },
        function(data, status) {
          suggestionArea.innerHTML = data['articles'];

          console.log(data['articles']);

          if (typeof data['articles'] === 'undefined' || data['articles'].indexOf("NoRecord") !== -1 || data['articles'].length < 1) {
            if (messageArea.value.length) {
              suggestionArea.innerHTML =
                "<div style=\"height: 100%; width: 100%;\" class=\"d-flex align-items-center justify-content-center\"><h4 class=\"text-muted\">No results</h4></div>";
            }
            else {
              suggestionArea.innerHTML =
                "<div style=\"height: 100%; width: 100%;\" class=\"d-flex align-items-center justify-content-center\"><h4 class=\"text-muted text-center\">Enter question description for results</h4></div>";
            }
          }
          // else {
          //     suggestionArea.innerHTML += "<h3>Do any of the above articles answer your question?</h3>";
          // }
        })
    }, 800);
  };
}

$(".open-at-start").modal("show");

