var resellerSignUp = {
  $reseller_sign_up_form       : null,
  $account_details             : null,
  $login_details               : null,
  $company_classification      : null,
  $company_classification_other: null,
  $logged_in                   : null,
  $existing_account_1          : null,
  $existing_account_2          : null,
  debounce_show_timeout        : null,
  $company_details             : null,
  $sign_up_button_container    : null,

  debouncedShowOtherClassification: function() {
    if (resellerSignUp.debounce_show_timeout !== null) {
      clearTimeout(parseInt(resellerSignUp.debounce_show_timeout));
    }
    resellerSignUp.debounce_show_timeout = setTimeout(function() {
      resellerSignUp.debounce_show_timeout = null;
      resellerSignUp.showOtherClassification();
    }, 300)
  },
  showOtherClassification         : function() {
    if (resellerSignUp.$company_classification.val() === 'other') {
      siteGlobals.animateShowVertical(resellerSignUp.$company_classification_other);
      resellerSignUp.$company_classification_other.find('input').removeProp('tabindex');
    }
    else {
      siteGlobals.animateHideVertical(resellerSignUp.$company_classification_other);
      resellerSignUp.$company_classification_other.find('input').prop('tabindex', -1);
    }
  },
  debouncedShowValidAccountFields : function() {
    if (resellerSignUp.debounce_show_timeout !== null) {
      clearTimeout(parseInt(resellerSignUp.debounce_show_timeout));
    }
    resellerSignUp.debounce_show_timeout = setTimeout(function() {
      resellerSignUp.debounce_show_timeout = null;
      resellerSignUp.showValidAccountFields();
    }, 300)
  },

  showValidAccountFields: function() {
    if (resellerSignUp.$existing_account_1.is(':checked')) {
      siteGlobals.animateShowVertical(resellerSignUp.$account_details);
      siteGlobals.animateHideVertical(resellerSignUp.$login_details);
      resellerSignUp.$company_details.removeClass('o-fade');
      resellerSignUp.$sign_up_button_container.removeClass('o-fade');
    }

    else if (resellerSignUp.$existing_account_2.is(':checked')) {
      siteGlobals.animateHideVertical(resellerSignUp.$account_details);
      siteGlobals.animateShowVertical(resellerSignUp.$login_details);
      resellerSignUp.$company_details.addClass('o-fade');
      resellerSignUp.$sign_up_button_container.addClass('o-fade');
    }
  },


  initiate: function() {
    resellerSignUp.$reseller_sign_up_form = $("#reseller-sign-up-form");
    if (resellerSignUp.$reseller_sign_up_form.length) {

      resellerSignUp.$company_details          = resellerSignUp.$reseller_sign_up_form.find('.company-details');
      resellerSignUp.$sign_up_button_container =
        resellerSignUp.$reseller_sign_up_form.find('.sign-up-button-container');

      resellerSignUp.$company_classification       = $('#company-classification');
      resellerSignUp.$company_classification_other = $('#company-classification-other');
      resellerSignUp.$logged_in                    = $('#logged-in');
      if (!resellerSignUp.$logged_in.length) {

        resellerSignUp.$existing_account_1 = $('#existing-account-1');
        resellerSignUp.$existing_account_2 = $('#existing-account-2');
        resellerSignUp.$account_details    = $('#account-details');
        resellerSignUp.$login_details      = $('#login-details');

        siteGlobals.instantHideVertical(resellerSignUp.$login_details);
        resellerSignUp.$login_details.removeClass('invisible');
        resellerSignUp.$login_details.insertBefore(resellerSignUp.$account_details);

        resellerSignUp.showValidAccountFields();

        resellerSignUp.$existing_account_1.on('input change blur keyup',function() {
          resellerSignUp.debouncedShowValidAccountFields();
        });
        resellerSignUp.$existing_account_2.on('input change blur keyup',function() {
          resellerSignUp.debouncedShowValidAccountFields();
        });
      }
      if (resellerSignUp.$company_classification.val() !== 'other') {
        siteGlobals.instantHideVertical(resellerSignUp.$company_classification_other);
        resellerSignUp.$company_classification_other.find('input').prop('tabindex', -1);
      }
      resellerSignUp.$company_classification.on('input change blur keyup',function() {
        resellerSignUp.debouncedShowOtherClassification();
      });

    }
  }
};

resellerSignUp.initiate();

