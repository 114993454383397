var TestimonialEngine = {
  highlightTestimonial: function(id_selector) {
    var $testimonial = $(id_selector)
      .css('background', '#61B7EA')
      // .css('background', '#FCB040')
      .css('color', '#FFF');
    setTimeout(function() {
      $testimonial.css('transition', 'all 1s cubic-bezier(0,1,0,-1)');
      setTimeout(function() {
        $testimonial.css('background', '').css('color', '');
        setTimeout(function() {
          $testimonial.css('transition', '');
        }, 1500);//allow the transition to complete before removing transition properties
      }, 50);//allow the transition property to be provisioned before calling on it
    }, 50);//allow the color flash to be set before setting the transition property.... other wise we get double flash

  }
};

// Run the code that is relevant to the review page
if ($('#reviews').length > 0) {
  // does the url have a hash value for a specific testimonial?
  var index = siteGlobals.url.indexOf('#testimonial-');
  // found it!
  if (index !== -1) {
    // get the string that jQuery will use to select the element
    var id_selector = siteGlobals.url.substring(index);
    setTimeout(
      function() {
        TestimonialEngine.highlightTestimonial(id_selector);
      }, 100); //slight delay allows page to render and scroll to element to work properly


  }
}

